import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

import config from '../config';
import { AlertModal, ModalMsg } from '../components/modals/AlertModal';
import { handleAxios } from '../components/modules/AxiosModule';
import LoadingIndicator from '../components/LoadingIndicator';

const UpdateInfo = () => {
  const { currentUser, setGoogleUser, loginCheck } = useAuth();

  const navigate = useNavigate();
  const apiURL = config.USER_UPDATE;

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [occupation, setOccupation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const checkRegex = (value) => {

    const sqlInjectionRegex = /['"\\;-]/;
    // 정규표현식으로 SQL 인젝션 패턴 감지
    if (sqlInjectionRegex.test(value)) {
      setErrorMessage('사용할 수 없는 문자가 포함.');
      return false;
    } else {
      setErrorMessage('');
      return true;
    }
  }

  const handleSubmit = async () => {
    const isLoggedIn = loginCheck({ showAlert: true, disableRedirect: true });
    // 비로그인인 경우
    if (!isLoggedIn) return
    
    const storedToken = JSON.parse(localStorage.getItem('token'));
    setIsLoading(true);
    try {
      const requestData = {
        name: name || currentUser.name,  // 추가된 name 필드
        nickname: currentUser.nickname,
        picture: currentUser.picture,
        phone: phone || currentUser.phone,  // 추가된 phone 필드
        occupation: occupation || currentUser.occupation,  // 추가된 occupation 필드
      };
      const response = await handleAxios("put", apiURL, requestData);

      if (response.data) {
        const myToken = {
          token: storedToken.token,
          expire: Date.now() + 60 * 60 * 18000,
          sub: currentUser.sub,
          email: currentUser.email,
          nickname: response.data.nickname,
          picture: response.data.picture,
          isAdmin: currentUser.isAdmin,
          name: response.data.name,
          phone: response.data.phone,
          occupation: response.data.occupation,
        }
        setGoogleUser(myToken);
        setTimeout(() => {
        }, 50);
        localStorage.setItem('token', JSON.stringify(myToken));
        navigate('/mypage');
      } else if (response.data && response.data.message) {
        setModalMsg(response.data.message);
        setIsOpen(true);
        // alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // setModalMsg(ModalMsg().invalidRequest);
        setIsOpen(true);
        setModalMsg(ModalMsg().invalidRequest);
        setIsOpen(true);
      } else {
        // setModalMsg(ModalMsg().needFillInput);
        setIsOpen(true);
        setModalMsg(ModalMsg().needFillInput);
        setIsOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>
      <div className='flex flex-col h-fit px-[25%] items-center text-center mt-28 gap-16
                      max-md:block max-md:w-[80%] max-md:mx-auto max-md:my-20 max-md:px-0'>
        <form className='flex flex-col w-full text-white justify-center items-center'
              onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <div className='flex w-full border-b border-gray-50 text-left mt-3 pb-2'>
            <span className='text-3xl font-bold max-md:text-xl'>개인정보변경</span>
          </div>
          <div className='flex w-full border-b border-gray-500 justify-between
                          max-md:flex-col-reverse max-md:justify-center'>
          {isLoading ? <LoadingIndicator /> : null}
            <div className='flex flex-col w-[50%] my-8 mr-auto gap-4
                            max-md:w-full'>
              <div className='flex w-full items-center mx-auto pt-2'>
                <span className='w-[30%] font-bold text-left'>닉네임</span>
                <span className='w-[70%] p-2 text-left rounded-md bg-transparent' >{currentUser.nickname}</span>
              </div>
              <div className='flex w-full justify-between items-center mx-auto pt-2'>
                <span className='w-[30%] font-bold text-left'>이름</span>
                <input className='w-[70%] p-2 border-2 border-gray-300 rounded-md bg-transparent' 
                       placeholder={currentUser.name}
                       value={name}
                       onChange={(e) => checkRegex(e.target.value) ? setName(e.target.value) : null}></input>
              </div>
              <div className='flex w-full justify-between items-center mx-auto pt-2'>
                <span className='w-[30%] font-bold text-left'>휴대폰번호</span>
                <input className='w-[70%] p-2 border-2 border-gray-300 rounded-md bg-transparent' 
                       placeholder={currentUser.phone || '정보없음'}
                       value={phone}
                       onChange={(e) => checkRegex(e.target.value) ?  setPhone(e.target.value) : null}></input>
              </div>
              <div className='flex w-full justify-between items-center mx-auto pt-2'>
                <span className='w-[30%] font-bold text-left'>직업</span>
                <input className='w-[70%] p-2 border-2 border-gray-300 rounded-md bg-transparent' 
                       placeholder={currentUser.occupation || '정보없음'}
                       value={occupation}
                       onChange={(e) =>  checkRegex(e.target.value) ? setOccupation(e.target.value): null}></input>
              </div>
            </div>
            <div className='w-[40%] my-auto max-md:w-full'>
            {currentUser.picture &&
              <img 
                className='max-w-[220px] w-full rounded-full mx-auto
                          max-md:w-[120px] max-md:h-[120px] max-md:mt-8'
                src={currentUser.picture} 
                alt="프로필 사진" />}
            </div>
          </div>
          <div className='flex gap-4 my-8'>
            <button className='px-12 py-3 font-bold border-2 rounded-full text-white'
                    onClick={() => navigate('/mypage')}>취소</button>
            <button className='px-12 py-3 font-bold bg-gradient-to-r from-blue-500 to-purple-600 rounded-full text-white'
                    type='submit'
                    disabled={!!errorMessage}>변경</button>
          </div>          
        </form>
      </div>
    </>
  );
}

export default UpdateInfo;