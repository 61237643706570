import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ResultGuest from '../components/ResultGuest';
import ResultMember from '../components/ResultMember';

const Result = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { checkCredits, fetchCredits } = useCredit();
  const { currentUser, loginCheck, authloading } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const canvasWidth = 1024;
  const canvasHeight = 1024;

  const { imageData, guest } = location.state;

  useEffect(() => {
    // location.state가 null이거나 undefined인 경우 바로 메인으로 리다이렉트
    if (!location.state) {
      navigate('/');
      return;
    }
    if (!imageData) {
      navigate('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, navigate, location.state, authloading]);

  if (!location.state) { return null; }

  return (
    <>
      {!guest && (
        <ResultMember
          imageData={imageData}
          loginCheck={loginCheck}
          fetchCredits={fetchCredits}
          checkCredits={checkCredits}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
        />
      )}
      {guest === true && (
        <ResultGuest
          imageData={imageData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
        />
      )}
    </>
  );
};

export default Result;