import React from 'react';

const ConfirmModal = ({ children, isOpen, setIsOpen, onClose, onConfirm, yesButtonId, noButtonId }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose} // 바깥 영역 클릭 시 모달 닫기
    >
      <div
        className="bg-white rounded-lg p-6 w-[550px] text-center shadow-lg"
        onClick={(e) => e.stopPropagation()} // 모달 내부 클릭은 이벤트 전파 차단
      >
        { children }
        <div className="flex justify-center space-x-4">
          <button
            onClick={onClose}
            className="w-24 bg-gray-300 text-gray-700 px-4 py-2 rounded-full hover:bg-gray-400"
            id={noButtonId}
          >아니오</button>
          <button
            onClick={onConfirm}
            className="w-24 px-4 py-2 rounded-full bg-gradient-to-r from-purple-500 to-blue-500 text-white hover:from-purple-600 hover:to-blue-600"
            id={yesButtonId}
          >예</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
