import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import { useCredit } from '../context/CreditContext';

import { AlertModal, ModalMsg } from '../components/modals/AlertModal';

const PaymentResult = () => {
  // 포트원 모바일 결제시 리디렉션 될 페이지
  const { fetchCredits } = useCredit()
  
  const urlParams = new URLSearchParams(window.location.search);
  const paymentId = urlParams.get('paymentId');

  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const [redirectPath, setRedirectPath] = useState('/');

  // 쿼리 데이터 읽어서 결제 검증 서버 호출
  useEffect(() => {
    if (paymentId) {
      axios.post(config.PORTONE_CHECK_VAL, {
        paymentId: paymentId,
      })
      .then(response => {
        const data = response.data;
        if (data.status === 'success') {
          // console.log('결제 검증 성공:', data.status);
          setModalMsg(`결제 성공. ${data.message}`);
          setIsOpen(true);
          // alert(`결제 성공. ${data.message}`);
          setRedirectPath('/');
          fetchCredits()
        } else {
          // console.log('결제 검증 실패:', data.message);
          setModalMsg(`결제 검증 실패:\n${data.message}`);
          setIsOpen(true);
          // alert(`결제 검증 실패: ${data.message}`);
          setRedirectPath('/pricing');
        }
      })
      .catch(error => {
        console.error('결제 검증 요청 오류:', error);
        setModalMsg(`결제 검증 요청 오류:\n${error.response.data.detail}`);
        setIsOpen(true);
        // alert(`결제 검증 요청 오류: ${error.response.data.detail}`);
        setRedirectPath('/pricing');
      });
    } else {
      console.error('결제 정보가 누락되었습니다.');
      setModalMsg(ModalMsg().paymentInfoMissing);
      setIsOpen(true);
      // alert('결제 정보가 누락됐습니다. 다시 시도해주세요.')
      setRedirectPath('/pricing');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    window.location.href = redirectPath;
  };

  return (
    <>
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg} handleClick={handleClose} handleClose={handleClose} ></AlertModal>

      <div style={{ marginTop: 150, textAlign: 'center', color: 'white'}}>
        <h1>Checking Payment Result...</h1>
        <p>결제 결과를 확인 중입니다...</p>
      </div>
    </>
  );
}

export default PaymentResult;
