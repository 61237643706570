import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { collection, deleteDoc, doc, getCountFromServer, getDocs, limit, orderBy, query, startAfter } from 'firebase/firestore';
import firestore from '../firebaseConfig';
import { deleteObject, getStorage, ref } from 'firebase/storage';

import '../styles/admin.css'
import AdminNavBar from '../components/adminNavBar';
import { ModalMsg } from '../components/modals/AlertModal';


function AdminReference() {
  const { currentUser, authloading } = useAuth();
  const navigate = useNavigate();

  const [selectedRating, setSelectedRating] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [lastVisible, setLastVisible] = useState(null); // 마지막 문서의 스냅샷을 저장하기 위한 상태
  const [isMoreData, setIsMoreData] = useState(true); // 추가 데이터가 있는지 여부를 추적하기 위한 상태
  const [totalCount, setTotalCount] = useState(0); // 총 데이터 수를 저장하기 위한 상태

  useEffect(() => {
    if (!authloading && !currentUser.isAdmin) {
      alert(ModalMsg().wrongAccess);
      navigate('/');
    }
  // eslint-disable-next-line
  }, [currentUser, authloading]);

  const apiKey = process.env.REACT_APP_PIXABAY_API_KEY;

  const fetchPixabayImageUrls = async (imgids) => {
    const urls = await Promise.all(
      imgids.map(async (id) => {
        try {
          const response = await fetch(`https://pixabay.com/api/?key=${apiKey}&id=${id}`);
          const data = await response.json();
          return data.hits.length > 0 ? data.hits[0].webformatURL : undefined;
        } catch (error) {
          console.error('Error fetching Pixabay image:', error);
          return undefined;
        }
      })
    );
    return urls.filter(url => url !== undefined);
  };

  const fetchRatingData = async (rating, isInitial) => {
    setLoading(true);
    const q = query(
      collection(firestore, `Rating/ImageManipulation/rating${rating}`),
      orderBy('date', 'desc'),
      limit(10),
      ...(lastVisible && !isInitial ? [startAfter(lastVisible)] : [])
    );
    const querySnapshot = await getDocs(q);
    const fetchedData = [];
    for (const doc of querySnapshot.docs) {
      const docData = { id: doc.id, ...doc.data() };
      if (docData.imgids && docData.imgids.length > 0) {
        docData.pixabayImageUrls = await fetchPixabayImageUrls(docData.imgids);
      } else {
        docData.pixabayImageUrls = [];
      }
      fetchedData.push(docData);
    }
    setData(isInitial ? fetchedData : [...data, ...fetchedData]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setIsMoreData(!querySnapshot.empty && querySnapshot.docs.length === 10);
    setLoading(false);

    // 총 데이터 수 업데이트
    const countSnapshot = await getCountFromServer(collection(firestore, `Rating/ImageManipulation/rating${rating}`));
    setTotalCount(countSnapshot.data().count);
  };

  useEffect(() => {
    if (selectedRating !== null) {
      fetchRatingData(selectedRating, true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRating]);

  const handleScroll = useCallback(() => {
    const distanceFromBottom = document.documentElement.offsetHeight - (window.innerHeight + document.documentElement.scrollTop);
    if (distanceFromBottom < 400 && isMoreData && !loading) {
      fetchRatingData(selectedRating, false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRating, isMoreData, loading, lastVisible, data]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const selectRating = (rating) => {
    setSelectedRating(rating);
    setData([]);
    setLastVisible(null);
    setIsMoreData(true);
  };

  const storage = getStorage();

  const handleDelete = (docId, selectedRating) => {
    const isConfirmed = window.confirm("정말로 삭제하겠습니까?");
    if (isConfirmed) {
      // 비밀번호를 입력받는 추가적인 확인 단계
      const password = prompt("비밀번호를 입력해주세요:");
      const correctPassword = "나는빡빡이다";
      
      if (password === correctPassword) {
        deleteDocumentAndImage(docId, selectedRating);
      } else {
        alert("비밀번호가 틀렸습니다. 삭제가 취소되었습니다.");
      }
    } else {
      // console.log("삭제가 취소되었습니다.");
    }
  };

  const deleteDocumentAndImage = async (docId, selectedRating) => {

    try {
      // Firestore에서 문서 삭제
      await deleteDoc(doc(firestore, `Rating/ImageManipulation/rating${selectedRating}`, docId));
      // console.log("Document successfully deleted!");
  
      // Firebase Storage에서 이미지 삭제
      const imageRef = ref(storage, `ImageManipulation/Rating${selectedRating}/${docId}`);
      await deleteObject(imageRef);
      // console.log("Image successfully deleted!");
      setData(currentData => currentData.filter(item => item.id !== docId));
      setTotalCount(totalCount - 1); // 총 데이터 수 감소
    } catch (error) {
      console.error("Error removing document and image: ", error);
    }
  };

  return (
    <>
      <AdminNavBar />
      <section className='prompt'>
        {[0, 1, 2, 3, 4, 5].map((rating) => (
          <button
            key={rating}
            onClick={() => selectRating(rating)}
            className={selectedRating === rating ? 'active' : ''}
          >
            {rating}점
          </button>
        ))}

        <div style={{ color: 'white', margin: 10, fontSize: 20}}>
          <p>총 데이터 수: {totalCount}</p>
        </div>

        <div className='list'>
          {loading && <p>데이터를 불러오는 중...</p>}
            {data.map((item) => (
              <div className='data' key={item.id}>
                <img src={item.imageData} alt="Rating Preview" />
                <div className='data-info'>
                  <div>
                    <p>Prompt: {item.prompt}</p>
                    <p>Phone: {item.phone}</p>
                    <p>E-Mail: {item.email}</p>
                    <p>Date: {item.date}</p>
                    {currentUser && currentUser.isAdmin && (
                      <button onClick={() => handleDelete(item.id, selectedRating)}>삭제</button>
                    )}
                  </div>
                  <div className='pixabay-images'>
                    {item.imgurls && item.imgurls.map((url, index) => (
                      <img key={index} src={url} alt={`Pixabay Preview ${index}`} />
                    ))}
                    {item.pixabayImageUrls && item.pixabayImageUrls.map((url, index) => (
                      <img key={`new-${index}`} src={url} alt={`New Pixabay Preview ${index}`} />
                    ))}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </>
  );
};
export default AdminReference;
