import React, { useRef, useEffect } from 'react';
import { gradientText } from './modules/colors';

const StudioImageScrolls = () => {
  const sliderRef = useRef(null);
  const spanGradient = `${gradientText} font-extrabold`;

  const images = [
    { imageData: require('../assets/images/studio/scroll-00.png')},
    { imageData: require('../assets/images/studio/scroll-01.png')},
    { imageData: require('../assets/images/studio/scroll-02.png')},
    { imageData: require('../assets/images/studio/scroll-03.png')},
    { imageData: require('../assets/images/studio/scroll-04.png')},
    { imageData: require('../assets/images/studio/scroll-05.png')},
    { imageData: require('../assets/images/studio/scroll-06.png')},
    { imageData: require('../assets/images/studio/scroll-07.png')},
    { imageData: require('../assets/images/studio/scroll-08.png')},
    { imageData: require('../assets/images/studio/scroll-09.png')},
  ];

  const extendedImages = [...images, ...images];

  useEffect(() => {
    const scrollAmount = 1;
    const slider = sliderRef.current;

    const slideImages = setInterval(() => {
      if (slider) {
        slider.scrollLeft += scrollAmount;
        if (slider.scrollLeft >= slider.scrollWidth / 2) {
          slider.scrollLeft = 0;
        }
      }
    }, 15);

    return () => clearInterval(slideImages);
  }, []);

  return (
    <div className="relative sm:mt-0 mt-10 overflow-hidden mb-40">
      <div
        className="relative flex flex-nowrap overflow-hidden"
        ref={sliderRef}
      >
        {extendedImages.map((image, index) => (
          <div
            key={index}
            className="flex-none mx-5 w-[200px] sm:w-[350px] relative"
          >
            <div className="relative">
              <img
                src={image.imageData}
                alt={index}
                className="w-full h-auto rounded-lg"
              />
            </div>
          </div>
        ))}
      </div>

      <div className='md:mt-10 mt-4 text-white font-bold md:text-[50px] text-[20px] text-center'>
        <p>AI가 만들어주는 창의적이고 신기한 이미지로</p>
        <p><span className={spanGradient}>독특한 아이디어</span>를 얻어 보세요!</p>
      </div>
    </div>
  );
};

export default StudioImageScrolls;