import React, { createContext, useState } from 'react';
import ConfirmModal from '../components/modals/ConfirmModal';

export const PromptContext = createContext();

export const PromptProvider = ({ children }) => {
  const [prompt, setPrompt] = useState('');
  const [shouldConfirm, setShouldConfirm] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [onConfirm, setOnConfirm] = useState(() => () => {});

  const confirmPrompt = (image, handleSubmit) => {
    setCurrentImage(image);
    setShouldConfirm(true); 
    setOnConfirm(() => () => {
      handleSubmit(image.prompt);
      setShouldConfirm(false);
      setCurrentImage(null);
    });
  };

  const cancelPrompt = () => {
    setShouldConfirm(false);
    setCurrentImage(null);
  };

  return (
    <PromptContext.Provider value={{ prompt, setPrompt, confirmPrompt, shouldConfirm }}>
      {children}
      {shouldConfirm && currentImage && (
        <ConfirmModal 
          isOpen = {shouldConfirm} 
          onClose = {cancelPrompt} 
          onConfirm = {onConfirm}
          yesButtonId = "land-sample-yes"
          noButtonId = "land-sample-no"
        >
          <img
            src={currentImage.url}
            alt="Preview"
            className="w-full object-cover rounded-md mb-3"
          />
          <p className="text-lg font-semibold mb-2">{currentImage.prompt}</p>
          <p className="text-lg text-blue-600 font-bold mb-4">이미지를 생성하시겠습니까?</p>
        </ConfirmModal>
      )}
    </PromptContext.Provider>
  );
};