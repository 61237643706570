import React, { useEffect, useState } from 'react'
import '../styles/StudioLanding.css'
import StudioLandingInner from '../components/StudioLandingInner';
import StudioLandingIntroduction from '../components/StudioLandingIntroduction';
import StudioImageScrolls from '../components/StudioImageScrolls';
import LandingCenterBox from '../components/LandingCenterBox';
import Footer from '../components/Footer';

import studio4 from '../assets/images/studio/studio-sec4.png'
import studio5 from '../assets/images/studio/studio-sec5.png'
import SignupModal from '../components/modals/SignupModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { GradientOutlineButton } from '../components/Buttons';
import { AlertModal, ModalMsg } from '../components/modals/AlertModal';

const StudioGenLanding = () => {
  const navigate = useNavigate();
  const { currentUser, googleLogin } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  useEffect(() => {
    sessionStorage.removeItem('imgUuid');
  }, []);

  // 클릭 시 conceptgenerator로 이동
  const handleButtonClick = () => {
    if (!currentUser) {
      setShowModal(true);
    }
    if (currentUser) {
      navigate('/conceptgenerate');
    }
  }

  return (
    <>
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>
      <SignupModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        message="서비스를 이용하시려면"
        message1="로그인/회원가입이 필요합니다."
        message2="로그인/회원가입 하시겠습니까?"
        onConfirm={() => {
          const userAgent = navigator.userAgent || navigator.vendor || window.opera;
          const isInAppBrowser = /Instagram|FBAN|FBAV|FB_IAB|FBIOS|FB4A|FBSV|Twitter|Line|KAKAOTALK|NAVER|Naver|Firefox|FxiOS/i.test(userAgent);
          if (isInAppBrowser && /iPhone|iPad|iPod/i.test(userAgent)) {
            setShowModal(false);
            setIsOpen(true);
            setModalMsg(ModalMsg().iosInApp);
          }
          else {
            googleLogin();
            setShowModal(false);
          }
        }}
      />

      <div className='StudioLanding'>
        <StudioLandingInner
          handleButtonClick={handleButtonClick}
        />
      </div>
      <StudioImageScrolls />
      <StudioLandingIntroduction />

      <LandingCenterBox
        imageSrc={studio4}
        text={[
          "블로그, SNS, 마켓 홍보에 사용해",
          "마케팅 효과를 올려보세요!"
        ]}
        highlightWords={["마케팅 효과"]}
      />

      <LandingCenterBox
        imageSrc={studio5}
        text={[
          "현업 영상 편집자도 스톡 이미지로 활용 중!",
          "작업물에 소스나 레퍼런스 자료로 활용해 보세요!"
        ]}
        highlightWords={["스톡 이미지"]}
      />

      <div className='flex mt-20 justify-center'>
        <GradientOutlineButton
          btnId='studio-land-start-bottom'
          text='무료로 시작하기'
          mobileFontSize='16px'
          size='w-[220px] h-[42px] sm:w-[270px] sm:h-[50px]'
          handleClick={handleButtonClick}
        />
      </div>
      
      <div className='flex-colum text-center text-white font-bold mt-4 mb-40 p-4'>
        <p className='text-2xl'>Copyright Free!!</p>
        <p className='text-sm'><span className='text-yellow-500'>로보브러시에서 생성한 이미지</span>들은 상업적으로 이용 가능합니다.</p>
        <p className='mt-5 text-sm text-gray-300'>단 타인의 작품, 초상권 등을 무단으로 사용한 경우에 대한 책임은 본인에게 있습니다.</p>
      </div>

      <Footer />
    </>
  )
}

export default StudioGenLanding