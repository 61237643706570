import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCoins } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import { GradientOutlineButton, OutlineButton } from './Buttons';
import LoadingIndicator from './LoadingIndicator';
import { AlertModal, ModalMsg } from './modals/AlertModal';
import SignupModal from './modals/SignupModal';
import { copyImageToClipboard, b64ToCanvas, downloadImage, } from './modules/canvasFuntions';
import { urlToB64 } from './modules/functions';

const ResultGuest = ({ imageData, isLoading, setIsLoading }) => {
  const { currentUser, googleLogin, guestWater } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackTarget, setFeedbackTarget] = useState(null);
  const [loadingText, setLoadingText] = useState('')

  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  // 재생성 요청
  const handleLogin = async () => {
    setShowModal(true);
  }

  // 회원 정보 변경(로그인) 의존성 실행. url 변경해서 리디렉션 (컴포넌트 변경 유발)
  useEffect(() => {
    if (imageData.url && currentUser && imageData.uuid) {
      const updatedUrl = imageData.url.replace('temp-container', 'robobrush-container');
      const targetUrl = guestWater === false ? updatedUrl : imageData.url;
      navigate('/mycanvas-result', { state: { imageData: { url: targetUrl } } });
    }
    // eslint-disable-next-line
  }, [currentUser, guestWater]);

  const showFeedback = (target, message) => {
    setFeedbackTarget(target);
    setFeedbackMessage(message);
    setTimeout(() => {
      setFeedbackMessage(null);
      setFeedbackTarget(null);
    }, 2000); // 2초 뒤 메시지 제거
  };

  // 복사
  const handleCopy = async () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    try {
      setLoadingText("이미지를 복사하는 중입니다.");
      setIsLoading(true);
      if (isSafari) {
        // Safari 브라우저인 경우 URL을 텍스트로 클립보드에 복사
        await navigator.clipboard.writeText(imageData.url);
      } else {
        //////////////////// url -> b64 호출
        const b64Data = await urlToB64(imageData.url);
        ///////////////////// b64 -> 가상의 canvas
        const canvas = await b64ToCanvas(b64Data);
        const ref = { current: canvas }
        await copyImageToClipboard(ref);
      }
      setModalMsg(ModalMsg().copySuccess);
      setIsOpen(true);
      showFeedback('copy', '복사 완료! 로그인 하면 원본사진을 복사 할 수 있어요.');
    } catch (error) {
      setModalMsg(ModalMsg().imgLoadFailed);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  // 다운로드
  const handleDownload = async () => {
    try {
      setLoadingText("이미지를 복사하는 중입니다.")
      setIsLoading(true);

      //////////////////// url -> b64 호출
      const b64Data = await urlToB64(imageData.url);
      ///////////////////// b64 -> 가상의 canvas
      const canvas = await b64ToCanvas(b64Data);
      const ref = { current: canvas }
      downloadImage(ref);
      showFeedback('download', '다운로드 완료! 로그인 하면 원본사진을 받아 볼 수 있어요.');
    } catch (error) {
      setModalMsg(ModalMsg().imgLoadFailed);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <div className="flex flex-col items-center justify-start px-4 mt-20">
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>
      {isLoading && <LoadingIndicator text={loadingText} />}
      <div className="relative w-full max-w-[660px] h-auto aspect-square bg-gray-200 mb-4"
        onContextMenu={(e) => e.preventDefault()} // 우클릭 방지
      >
        <img src={imageData.url} alt="Selected" className="w-full h-full object-contain" />
      </div>
      {!isLoading && (
        <div className="grid grid-cols-2 gap-4 sm:w-[auto] w-[90%]">
          <GradientOutlineButton text="로그인 후 AI 편집" handleClick={handleLogin} btnId="canvas-result-guest-edit" />
          <GradientOutlineButton text="로그인 후 다시 생성" leftIcon={<FaCoins className="text-[16px] sm:text-[24px] align-middle" />} handleClick={handleLogin} btnId="canvas-result-guest-regen" />
          <div className="relative">
            <OutlineButton
              btnId="canvas-result-guest-copy"
              text="복사"
              handleClick={handleCopy}
            />
            {feedbackTarget === 'copy' && (
              <p className="text-red-500 text-[7px] sm:text-xs text-center absolute -bottom-5 left-0 right-0 transform animate-shake whitespace-nowrap">
                {feedbackMessage}
              </p>
            )}
          </div>
          <div className="relative">
            <OutlineButton
              btnId="canvas-result-guest-down"
              text="다운로드"
              handleClick={handleDownload}
            />
            {feedbackTarget === 'download' && (
              <p className="text-red-500 text-[7px] sm:text-xs text-center absolute -bottom-5 left-0 right-0 transform animate-shake whitespace-nowrap">
                {feedbackMessage}
              </p>
            )}
          </div>
        </div>
      )}

      <SignupModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        message="서비스를 이용하시려면"
        message1="로그인/회원가입이 필요합니다."
        message2="로그인/회원가입 하시겠습니까?"
        onConfirm={() => {
          const userAgent = navigator.userAgent || navigator.vendor || window.opera;
          const isInAppBrowser = /Instagram|FBAN|FBAV|FB_IAB|FBIOS|FB4A|FBSV|Twitter|Line|KAKAOTALK|NAVER|Naver|Firefox|FxiOS/i.test(userAgent);
          if (isInAppBrowser && /iPhone|iPad|iPod/i.test(userAgent)) {
            setShowModal(false);
            setIsOpen(true);
            setModalMsg(ModalMsg().iosInApp);
          }
          else {
            googleLogin();
            setShowModal(false);
          }
        }}
      />
    </div>
  );
}

export default ResultGuest;