import { handleAxios } from "./AxiosModule";
import config from "../../config"

const textGen = async (prompt, user_sub) => {
  // console.log("call textgen");
  const apiURL = config.PROMPT_GEN_APIURL;
  const requestData = {
    ...(user_sub && { user_sub: user_sub }),
    prompt: prompt,
  }
  const response = await handleAxios("post", apiURL, requestData);
  return response;
};

export { 
  textGen,
}