import React, { useRef, useEffect, useState } from 'react';
import { WandSparkles } from 'lucide-react';

export const GradientButton = ({ text, size='w-[100%] h-[36px] sm:w-[270px] sm:h-[50px]', mobileFontSize = '9px', fontsize = '18px', radius = '25px', handleClick, disabled, leftIcon, rightIcon, btnId }) => {
  return (
    <button
      id={btnId}
      onClick={handleClick}
      disabled={disabled}
      className={`${size} text-[${mobileFontSize}] sm:text-[${fontsize}]
        flex justify-center items-center text-white font-bold
        bg-gradient-to-r from-blue-500 to-purple-600 rounded-[${radius}]
        transition-all duration-200 ease-in-out
        ${!disabled ? 'hover:bg-[length:200%_100%] hover:bg-[position:100%_0%]' : ''}
        ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
      `}
    >
      <span className="flex-shrink-0 w-[18px] sm:w-[24px] ml-[15px]">
        {leftIcon && <span>{leftIcon}</span>}
      </span>
      <span className="flex-grow text-center">{text}</span>
      <span className="flex-shrink-0 w-[18px] sm:w-[24px] mr-[15px]">
        {rightIcon && <span>{rightIcon}</span>}
      </span>
    </button>
  );
};

export const OutlineButton = ({ text, size='w-[100%] h-[36px] sm:w-[270px] sm:h-[50px]', mobileFontSize = '9px', fontsize = '18px', radius = '25px', handleClick, disabled, leftIcon, rightIcon, btnId }) => {
  return (
    <button
      id={btnId}
      onClick={handleClick}
      disabled={disabled}
      className={`${size} sm:text-[${fontsize}] text-[${mobileFontSize}]
        flex justify-between items-center text-white font-bold border border-white bg-transparent
        rounded-[${radius}] transition-colors duration-200 ease-in-out
        ${!disabled ? 'hover:border-[#FFC107] hover:text-[#FFC107]' : ''}
        ${disabled ? 'opacity-50 cursor-not-allowed border-gray-400 text-gray-400' : ''}
        px-4
      `}
    >
      <span className="flex-shrink-0 w-[18px] sm:w-[24px] ml-[0px]">
        {leftIcon && <span>{leftIcon}</span>}
      </span>
      <span className="flex-grow text-center">{text}</span>
      <span className="flex-shrink-0 w-[18px] sm:w-[24px] mr-[0px]">
        {rightIcon && <span>{rightIcon}</span>}
      </span>
    </button>
  );
};

export const GradientOutlineButton = ({ text, size='w-[100%] h-[36px] sm:w-[270px] sm:h-[50px]', mobileFontSize = '9px', fontsize = '18px', radius = '25px', handleClick, disabled, activating, actText, leftIcon, rightIcon, btnId }) => {
  return (
    <button
      id={btnId}
      onClick={handleClick}
      disabled={disabled || activating}
      className={`${size} text-[${mobileFontSize}] sm:text-[${fontsize}] whitespace-nowrap
        relative inline-flex justify-center items-center text-white font-bold
        rounded-[${radius}] p-[2px] transition-all duration-200 ease-in-out
        bg-gradient-to-r from-blue-500 to-purple-600
        ${activating ? 'animate-waves' : ''}
        ${disabled || activating ? 'cursor-not-allowed' : 'hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-500'}
      `}
      style={{
        backgroundImage: activating ? 'linear-gradient(270deg, #3b82f6, #933bff, #3b82f6)' : '',
        backgroundSize: activating ? '200% 200%' : '100% 100%',
        backgroundPosition: activating ? '100% 50%' : 'center',
      }}>

      <span className={`
        flex grow text-center justify-center items-center w-full h-full rounded-[23px]
        ${activating ? 'bg-transparent' : 'bg-[#24303F]'}
        ${disabled ? 'bg-gray-600' : ''}
        ${!disabled && !activating ? 'hover:bg-[#1b2530]' : ''}
      `}>
        <span className="flex-shrink-0 w-[18px] sm:w-[24px] ml-[12px] mr-auto">
          {leftIcon && <span>{leftIcon}</span>}
        </span>
        {activating ? actText : text}
        <span className="flex-shrink-0 w-[18px] sm:w-[24px] mr-[12px] ml-auto">
          {rightIcon && <span>{rightIcon}</span>}
        </span>
      </span>
    </button>
  );
};

// 버튼 형태 인풋
export const AreaEditButton = ({ handleGenerateClick, isGenerating, progress, text, btnId }) => {
  const [parentWidth, setParentWidth] = useState(0);
  const [buttonWidth, setButtonWidth] = useState(48);
  const parentRef = useRef(null);

  // 부모 요소의 너비를 감지하는 useEffect
  useEffect(() => {
    if (parentRef.current) {
      const handleResize = () => {
        setParentWidth(parentRef.current.offsetWidth);
        setButtonWidth(window.innerWidth <= 768 ? 36 : 48);
      };
      handleResize();
      window.addEventListener('resize', handleResize); 
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [parentRef]);

  // translateX 계산 (progress에 따른 위치 계산)
  const translateXValue = isGenerating
  ? `translateX(${-(progress / 100) * (parentWidth - buttonWidth)}px)`
  : 'translateX(0)';

  return (
    <div className="relative w-[170px]" ref={parentRef}>
      <div className={`relative inline-flex justify-between items-center sm:w-full w-[100%] sm:h-[50px] h-[36px]
                      rounded-[25px] bg-transparent p-[2px] transition-all duration-200 ease-in-out
                      bg-gradient-to-r from-blue-500 to-purple-600
                      ${isGenerating ? 'animate-waves' : ''}`}
        style={{
          backgroundImage: isGenerating ? 'linear-gradient(270deg, #3b82f6, #933bff, #3b82f6)' : '',
          backgroundSize: isGenerating ? '200% 200%' : '100% 100%',
          backgroundPosition: isGenerating ? `${progress}% 50%` : 'center',
        }}
      >
        <button className={`flex justify-center items-center w-full h-full bg-[#24303F] rounded-[23px]
          ${isGenerating ? 'bg-transparent' : ''}`}
          id={btnId}
          onClick={handleGenerateClick}
          disabled={isGenerating}
        >
          <div className="relative sm:text-[16px] text-[10px] text-white mr-[20px]">
            {text}
          </div>
          <div
            className={`absolute right-0 sm:w-[48px] sm:h-[48px] w-[36px] h-[36px] rounded-full flex items-center justify-center
                        bg-gradient-to-r from-blue-500 to-purple-600
                        ${isGenerating ? 'cursor-not-allowed' : ''}`}
            style={{
              transform: translateXValue,
              transition: 'transform 0.1s ease-in-out',
            }}
          >
            <WandSparkles size={24} color="#FFFFFF" />
          </div>
        </button>
      </div>
    </div>
  );
};


const Buttons = () => {
  return (
    <div className="flex flex-col gap-4">
      <GradientButton text="Gradient Button" disabled />
      <OutlineButton text="Outline Button" disabled />
      <GradientOutlineButton text="Gradient Outline Button" disabled={false} activating={true} />
    </div>
  );
};

export default Buttons;