import { useCallback, useEffect } from 'react';
import { ModalMsg } from '../modals/AlertModal';
import { refToBlob, loadAndDrawImage } from './functions';

// 캔버스에 이미지를 로드
const useCanvasImageLoader = (canvasRef, backgroundImage, canvasWidth, canvasHeight) => {
  const loadImage = useCallback(() => {
    if (backgroundImage && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        loadAndDrawImage(context, canvas, img);
      };
      img.onerror = (error) => {
        alert(ModalMsg().imgLoadFailed);
      };
      img.src = `data:image/png;base64,${backgroundImage}`;
    }
  // eslint-disable-next-line
  }, [backgroundImage]);

  useEffect(() => {
    loadImage();
  // eslint-disable-next-line
  }, [loadImage, canvasWidth, canvasHeight]);

  return loadImage;
};

// 이미지 다운로드 함수
const downloadImage = (ref) => {
  if (ref.current) {
    const canvas = ref.current;
    const image = canvas.toDataURL();
    const link = document.createElement('a');
    link.download = 'image.png';
    link.href = image;
    link.click();
  }
};

// 이미지 클립보드에 복사하는 함수
const copyImageToClipboard = async (ref) => {
  if (ref.current) {
    const blob = await refToBlob(ref)
    await navigator.clipboard.write([
      new ClipboardItem({ 'image/png': blob })
    ]);
  }
};

////// b64 -> canvas
const b64ToCanvas = (b64Data) => {
  return new Promise((resolve, reject) => {
    try {
      // Create an Image element
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
  
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
  
        resolve(canvas);
      };

      img.src = `data:image/png;base64,${b64Data}`;
    } catch (error) {
      reject(error);
    }
  });
};

export { 
  useCanvasImageLoader,
  downloadImage,
  copyImageToClipboard,
  b64ToCanvas,
}