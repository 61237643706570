import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';
import { PromptContext } from '../context/PromptContext';
import { AlertModal, ModalMsg } from './modals/AlertModal';
import { textGen } from './modules/imageGen';
import { gradientText } from './modules/colors';

const LandingTitle = ({ setIsLoading }) => {
  const { currentUser, loginCheck } = useAuth();
  const { fetchCredits, checkCredits } = useCredit();
  const { setPrompt } = useContext(PromptContext);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const [titleText, setTitleText] = useState('크리스마스 트리 디자인');
  const [showTooltip, setShowTooltip] = useState(false);
  const titles = ['크리스마스 트리 디자인', '콜라 마시는 선글라스 낀 고양이', '크리스마스 텀블러 광고', '고급 레스토랑 와인', '호텔 배경 위스키 광고', '크리스마스 카드 템플릿', '몽환적인 배경 사진', '신비로운 분위기의 우주 배경 사진'];

  useEffect(() => {
    const interval = setInterval(() => {
      setTitleText((prevTitle) => {
        const currentIndex = titles.indexOf(prevTitle);
        const nextIndex = (currentIndex + 1) % titles.length;
        return titles[nextIndex];
      });
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tooltipTimer = setTimeout(() => {
      setShowTooltip(true);
    }, 2000);

    return () => clearTimeout(tooltipTimer);
  }, []);

  const handleSubmit = async (prompt) => {
    const isLoggedIn = loginCheck({ showAlert: false, disableRedirect: true });
    if (!isLoggedIn) {
      setIsLoading(true);
      try {
        //텍스트 생성
        const response = await textGen(prompt);

        if (response.data) {
          sessionStorage.setItem('imgUuid', response.data.uuid);
          navigate('/mycanvas-result', { state: { imageData: response.data, guest: true } });
        } else if (response.data && response.data.message) {
          setModalMsg(response.data.message);
          setIsOpen(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setModalMsg(ModalMsg().invalidRequest);
          setIsOpen(true);
        } else {
          setModalMsg(ModalMsg().generateFailed);
          setIsOpen(true);
        }
      } finally {
        setIsLoading(false);
        setPrompt('');
      }
      return;
    }

    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;
    setIsLoading(true);

    try {
      //텍스트 생성
      const response = await textGen(prompt, currentUser.sub);

      if (response.data) {
        await fetchCredits();
        navigate('/mycanvas-result', { state: { imageData: response.data } });
      } else if (response.data && response.data.message) {
        setModalMsg(response.data.message);
        setIsOpen(true);
        // alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          setModalMsg(ModalMsg().noCredits);
          setIsOpen(true);
          navigate('/pricing');
        } else {
          setModalMsg(ModalMsg().invalidRequest);
          setIsOpen(true);
        }
      } else {
        setModalMsg(ModalMsg().generateFailed);
        setIsOpen(true);
      }
    } finally {
      setIsLoading(false);
      setPrompt('');
    }
  };

  return (
    <div className="flex flex-col justify-center items-center text-center font-bold mt-10 relative">
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>
      
      <div
        onClick={() => handleSubmit(titleText)}
        id="land-title"
        className="relative lg:w-[650px] w-[300px] lg:pt-2 lg:pb-2 pt-2 pb-2 bg-gradient-to-b from-white to-gray-300 text-xl lg:rounded-[15px] rounded-[10px] lg:text-2xl text-black
                  hover:bg-gradient-to-b hover:from-gray-300 hover:to-gray-100 cursor-pointer">

        {showTooltip && (
          <div className="absolute lg:top-[-7px] lg:right-[-150px] top-[40px] right-[-10px] bg-[#00FFB2] text-black text-sm p-2 rounded shadow-lg z-10">
            클릭하면 해당 텍스트로 생성해볼 수 있어요!
            <div className="absolute lg:bottom-[5px] bottom-[25px] lg:left-[-5px] w-4 h-4 bg-[#00FFB2] transform rotate-45 z-[-1]"></div>
            <button
              className="absolute top-[-10px] right-[-10px] bg-red-600 text-white w-5 h-5 rounded-full text-xs flex items-center justify-center"
              onClick={(e) => {
                e.stopPropagation(); // 이벤트 버블링 방지
                setShowTooltip(false);
              }}
              >
              ×
            </button>
        </div>
        )}
        
        {titleText}

      </div>
      <div className="text-white lg:mt-4 mt-2 text-[40px] lg:text-7xl">
        <span className={gradientText}>
          로보브러시
        </span>
        로 해결!
      </div>
    </div>
  );
};

export default LandingTitle;
