import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGallery } from '../context/GalleryContext';
import { PromptContext } from '../context/PromptContext';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';
import { AlertModal, ModalMsg } from './modals/AlertModal';
import { textGen } from './modules/imageGen';

const LandingGallery = ({ setIsLoading }) => {
  const { currentUser, loginCheck } = useAuth();
  const { galleryImages } = useGallery();
  const { fetchCredits, checkCredits } = useCredit();
  const [highlightIndex, setHighlightIndex] = useState(null);
  const { setPrompt, confirmPrompt } = useContext(PromptContext);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  // 이미지 생성 요청 함수
  const handleSubmit = async (prompt) => {
    const isLoggedIn = loginCheck({ showAlert: false, disableRedirect: true });
    // 비로그인인 경우
    if (!isLoggedIn) {
      setIsLoading(true);
      try {
        //텍스트 생성
        const response = await textGen(prompt);

        if (response.data) {
          sessionStorage.setItem('imgUuid', response.data.uuid);
          navigate('/mycanvas-result', { state: { imageData: response.data, guest: true } });
        } else if (response.data && response.data.message) {
          setModalMsg(response.data.message);
          setIsOpen(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setModalMsg(ModalMsg().invalidRequest);
          setIsOpen(true);
        } else {
          setModalMsg(ModalMsg().generateFailed);
          setIsOpen(true);
        }
      } finally {
        setIsLoading(false);
        setPrompt('')
      }
      return;
    }

    // 로그인 상태인 경우 크레딧 체크 후 이후 실행
    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;
    setIsLoading(true);

    try {
      //텍스트 생성
      const response = await textGen(prompt, currentUser.sub);

      if (response.data) {
        await fetchCredits();
        navigate('/mycanvas-result', { state: { imageData: response.data } });
      } else if (response.data && response.data.message) {
        setModalMsg(response.data.message);
        setIsOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          setModalMsg(ModalMsg().noCredits);
          setIsOpen(true);
          navigate('/pricing');
        } else {
          setModalMsg(ModalMsg().invalidRequest);
          setIsOpen(true);
        }
      } else {
        setModalMsg(ModalMsg().generateFailed);
        setIsOpen(true);
      }
    } finally {
      setIsLoading(false);
      setPrompt('')
    }
  }

  // 이미지의 버튼 클릭시 실행
  const handlePromptClick = (image) => {
    setPrompt(image.prompt);
    setTimeout(() => {
      confirmPrompt(image, handleSubmit);
    }, 100);
  };
  

  // 랜덤으로 하이라이트 && 주기적으로 변경
  useEffect(() => {
    if (isHovered) return; // 마우스 호버 중이면 하이라이트 변경 중지

    const isMobile = window.innerWidth < 768; // 모바일: width < 768px
    const maxItems = isMobile ? 4 : 14; // 모바일: 최대 4개, 데스크탑: 최대 14개

    const interval = setInterval(() => {
      setHighlightIndex(Math.floor(Math.random() * Math.min(galleryImages.length, maxItems)));
    }, 1500); // 1.5초마다 실행

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 클리어
  }, [galleryImages, isHovered]);

  
  return (
    <div className="grid grid-cols-7 gap-3 sm:mt-4 px-6 pb-12 max-sm:grid-cols-2 max-md:grid-cols-2 max-md:px-2"
      onMouseEnter={() => setIsHovered(true)} // 마우스가 갤러리 전체에 들어갈 때
      onMouseLeave={() => setIsHovered(false)} // 마우스가 갤러리 전체에서 나갈 때
    >
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>

      {galleryImages.map((image, index) => (
        <div
          className={`relative w-full flex items-center justify-center rounded-[15px] cursor-pointer overflow-hidden
                      ${highlightIndex === index ? 'border-4 border-[#FFC800] bg-black bg-opacity-50' : 'bg-gray-200'}
                      hover:border-4 hover:border-[#FFC800] hover:bg-black hover:bg-opacity-50 group`}
          key={image.id}
          onMouseEnter={() => setHighlightIndex(null)}
          id="land-sample"
          onClick={() => handlePromptClick(image)}
        >
          {/* Prompt 텍스트 영역 */}
          <div
            className={`absolute top-0 left-0 w-full text-white text-center py-1 px-2
                        text-[14px] truncate overflow-hidden whitespace-nowrap
                        ${
                          highlightIndex === index
                            ? 'inset-0 flex items-center justify-center text-[18px] bg-opacity-50 bg-black'
                            : 'bg-black bg-opacity-50'
                        }
                        group-hover:inset-0 group-hover:flex group-hover:items-center group-hover:justify-center
                        group-hover:text-[18px]`}
          >
            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap block">
              {image.prompt}
            </p>
          </div>

          {/* 이미지 */}
          <img
            className={`object-cover w-full h-full
                        ${highlightIndex === index ? 'opacity-40' : ''}
                        group-hover:opacity-40`}
            src={image.url}
            alt={`${image.id}`}
          />

          {/* 오버레이 */}
          <div
            className="absolute inset-0 z-10"
            onContextMenu={(e) => e.preventDefault()} // 우클릭 방지
            onTouchStart={(e) => e.preventDefault()}  // 모바일 긴 터치 방지
          ></div>

          {/* 생성하기 텍스트 */}
          <div
            className={`absolute bottom-6 text-[#FFC800] font-bold text-[18px] text-center opacity-0
                        group-hover:opacity-100 group-hover:translate-y-2 transition-all
                        ${highlightIndex === index ? 'opacity-100 translate-y-2' : ''}`}
          >
            클릭하고 생성해보기
          </div>
        </div>
      ))}
    </div>
  );
};

export default LandingGallery;
