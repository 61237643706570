import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import '../styles/Pricing.css';
import QnA from '../components/QnA';
import Footer from '../components/Footer';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';
import * as PortOne from "@portone/browser-sdk/v2";
import config from '../config';
import axios from 'axios';
import { handleAxios } from '../components/modules/AxiosModule';
import LoadingIndicator from '../components/LoadingIndicator';
import PhoneModal from '../components/modals/PhoneModal';

import { AlertModal, ModalMsg } from '../components/modals/AlertModal';

// 플랜 UI
const Plan = ({ title, subtitle, features, price, discount, discountPrice, highlight, onClick, isActive }) => (//isSubscription, isActive }) => (
  <div className='plans' onClick={onClick ? onClick : undefined}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h3>{title}</h3>
      {/* {isActive && <span className="active-badge">현재 이용중</span>} */}
    </div>
    <p>{subtitle}</p>
    {highlight && <h4 style={{color: '#ffc700'}}><FaCheck className="check-icon" style={{color: '#ffc700'}} />{highlight}</h4>}
    {features.map((feature, index) => (
      // 
      <h4 key={index} style={{ display: 'flex'}}> 
        <FaCheck className="check-icon" />
        {feature}
      </h4>
    ))}
    {price && (
      <div className='price'>
        {discount && (
          <div className='discount'>
            <p>{discount}</p>
            <h5>{price}</h5>
          </div>
        )}
        <h4>{discountPrice || price}</h4>
      </div>
    )}
  </div>
);

// eslint-disable-next-line
const Menu = ({ label, active, onClick }) => (
  <div
    className={`menu ${active ? 'active' : ''}`}
    onClick={() => onClick(label)}
  >
    {label}
  </div>
);

// 상품 데이터
const pricingData = {
  'AI 도구': {
    // description: 'AI로 이미지를 생성하거나 합성할 수 있어요.',
    description: '크레딧 구매로 더 많은 AI 편집이 가능해요.',
    plans: [
      {
        title: '무료 플랜',
        features: ['매일 무료 크레딧 7개 제공', '무제한 배경 제거'],
        isActive: true
      },
      {
        title: '100 크레딧 구매',
        subtitle: '1일 최대 10번 구매할 수 있어요!',
        features: ['매일 무료 크레딧 7개 제공', '무제한 배경 제거', '워터마크 제거'],
        price: '10,000원',
        discount: '서비스 출시 기념 50% 할인!',
        discountPrice: '5,000원'
      }
    ]
  },
  // '스튜디오': {
  //   description: '30초만에 나만의 광고 디자인을 완성할 수 있어요.',
  //   plans: [
  //     {
  //       title: '무료 플랜',
  //       features: ['매일 무료 크레딧 10개 제공', '무제한 배경 제거'],
  //       isActive: false
  //     },
  //     {
  //       title: '스튜디오 구독',
  //       highlight: '제품 편집 자동화 서비스',
  //       features: ['AI 도구 무제한 이용', '무제한 배경 제거', '워터마크 제거'],
  //       price: '29,000원',
  //       discount: '서비스 출시 기념 65% 할인!',
  //       discountPrice: '9,900원',
  //       // 구독인 경우
  //       isSubscription: true
  //     }
  //   ]
  // }
};

const Pricing = () => {
  const { currentUser, loginCheck, setGoogleUser } = useAuth();
  const { fetchCredits } = useCredit()
  // eslint-disable-next-line
  const [activeMenu, setActiveMenu] = useState('AI 도구');
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  
  // 휴대폰 번호 검증
  const checkPhone = (plan) => {
    const isLoggedIn = loginCheck({ showAlert: true, disableRedirect: true });
    if (!isLoggedIn) return;

    const isValidPhoneNumber = (value) => {
      return /^\d{3}-\d{4}-\d{4}$/.test(value) || /^\d{11}$/.test(value);
    };

    if (currentUser.phone && isValidPhoneNumber(currentUser.phone)) {
      // 폰 번호가 유효하면 결제 함수 실행
      handlePayment(plan, currentUser.phone);
    } else {
      setSelectedPlan(plan);
      setShowModal(true);
    }
  };

  // 폰 번호 저장 함수
  // updateInfo.jsx의 회원 정보 저장 함수 가져와서 다소 변형
  const savePhone = async () => {
    const storedToken = JSON.parse(localStorage.getItem('token'));
    setIsLoading(true);
    try {
      const requestData = {
        name: currentUser.name || '',
        nickname: currentUser.nickname || '',
        picture: currentUser.picture,
        phone: modalValue,
        occupation: currentUser.occupation || '',
      };
      const response = await handleAxios("put", config.USER_UPDATE, requestData);

      if (response.data) {
        const myToken = {
          token: storedToken.token,
          expire: Date.now() + 60 * 60 * 18000,
          sub: currentUser.sub,
          email: currentUser.email,
          nickname: response.data.nickname,
          picture: response.data.picture,
          isAdmin: currentUser.isAdmin,
          name: response.data.name,
          phone: response.data.phone,
          occupation: response.data.occupation,
        }
        setGoogleUser(myToken);
        setTimeout(() => {
        }, 50);
        localStorage.setItem('token', JSON.stringify(myToken));
        // 저장 완료 후 결제 실행
        setTimeout(() => {
          if (selectedPlan) {
            handlePayment(selectedPlan, modalValue);
          }
        }, 100);
      } else if (response.data && response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setModalMsg(ModalMsg().invalidRequest);
        setIsOpen(true);
        // Alerts.invalidRequest(error);
      } else {
        setModalMsg(ModalMsg().needFillInput);
        setIsOpen(true);
        // Alerts.needFillInput(error);
      }
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  }

  // 결제 호출
  const handlePayment = (plan, phoneNumber) => {
    // eslint-disable-next-line
    const { title, discountPrice, isSubscription } = plan;
    const amount = discountPrice.replace(/,/g, '').replace('원', '');
    const paymentId = `${currentUser.sub}${new Date().getTime()}`;
    const channelKey = 'channel-key-27241b7a-03c7-452d-a8ff-13a86c7c0472';
    
    PortOne.requestPayment({
      storeId: "store-62fd6baa-6930-496f-9dc8-7a9f88c0c25b",
      paymentId: paymentId,
      orderName: title,
      totalAmount: amount,
      currency: "CURRENCY_KRW",
      channelKey: channelKey,
      payMethod: "CARD",
      // 배포시 주소 변경 필수!!! 안 하면 모바일 결제 안됨!!!
      // redirectUrl: `http://localhost:3000/payment-result?paymentId=${paymentId}`,
      // 이 주석 아래가 배포용
      redirectUrl: `https://robobrush.ai/payment-result?paymentId=${paymentId}`,
      // redirectUrl: `https://green-ground-0c992cb00-141.eastasia.3.azurestaticapps.net/payment-result?paymentId=${paymentId}`,
      customer: {
        phoneNumber: phoneNumber,
      },
    }).then((rsp) => {
      if (rsp.code != null) {
        // 실패
        setModalMsg(ModalMsg(rsp.message).paymentFail);
        setIsOpen(true);
        // alert(`결제 실패: ${rsp.message}`)
      } else {
        // 성공
        axios.post(config.PORTONE_CHECK_VAL, {
          paymentId: rsp.paymentId,
        })
        .then(response => {
          const data = response.data;
          if (data.status) {
            setModalMsg(ModalMsg(100).paymentSuccess);//지급된 크레딧을 전달.
            setIsOpen(true);
            // alert(`결제 완료. 100 크레딧이 지급됐습니다.`)
            fetchCredits()
          } else {
            setModalMsg(ModalMsg(data.message).paymentValidFail);
            setIsOpen(true);
            // alert(`결제 검증 실패: ${data.message}`);
          }
        })
        .catch(error => {
          console.error('결제 검증 요청 오류:', error);
          setModalMsg(ModalMsg(error.response.data.detail).paymentValidReqError);
          setIsOpen(true);
          // alert(`결제 검증 요청 오류: ${error.response.data.detail}`)
        });
      }
    }).catch((error) => {
      console.error('결제 요청 오류:', error);
    })
  };

  return (
    <>
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>

      <div className='min-h-screen'>
        {isLoading && <LoadingIndicator text='결제 진행중입니다.' />}
        
        <PhoneModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          message="결제 주문을 위해"
          message1="휴대폰 번호를 입력해주세요."
          modalValue={modalValue}
          setModalValue={setModalValue}
          onConfirm={() => {
            savePhone();
          }}
        />
        
        <div className='pricing'>
          {/* <div>
            <h1>나에게 맞는 요금제 찾기</h1>
          </div> */}
          {/* <div className='priceToggler'>
            {Object.keys(pricingData).map((menu) => (
              <Menu
                key={menu}
                label={menu}
                active={activeMenu === menu}
                onClick={setActiveMenu}
              />
            ))}
          </div> */}

          <div className='priceContent'>
            <div className='content'>
              <h2>{pricingData[activeMenu].description}</h2>
              <div className='plans-container'>
                {pricingData[activeMenu].plans.map((plan, index) => (
                  <Plan
                    key={index}
                    {...plan}
                    onClick={plan.price ? () => checkPhone(plan) : null}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <QnA />
      </div>
      <Footer />
    </>
  );
};

export default Pricing;