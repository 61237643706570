import React, { useEffect, useRef, useState } from 'react';
import '../styles/ComparisonSlider.css';

const ComparisonSlider = ({ img1, img2, bg1color, bg2color, autoSlide = false, onSlideEnd  }) => {
  const [position, setPosition] = useState(100);
  const [sliderToggle, setSliderToggle] = useState(false);
  const sliderRef = useRef(null);

  // 자동 슬라이드
  useEffect(() => {
    if (!autoSlide) return;

    let slideDirection = -100;
    const interval = setInterval(() => {
      setPosition((prevPosition) => {
        let nextPosition = prevPosition + slideDirection;
        if (nextPosition <= 0) {
          setSliderToggle(!sliderToggle)
          nextPosition = 100; // 우측 끝에서 다시 시작
          setTimeout(() => {
            onSlideEnd();
          }, 0);
        }
        return nextPosition;
      });
    }, 2500);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSlide, onSlideEnd]);

  return (
    <div className="ComparisonSlider">
      <div className="relative-box">
      <button 
        disabled 
        className='button-original' 
        style={{ display: sliderToggle ? 'none' : 'block' }}>변환 전</button>
      <button 
        disabled 
        className='button-done'
        style={{ display: !sliderToggle ? 'none' : 'block' }}>AI 변환 후</button>
      </div>
      <div ref={sliderRef} className="comparison-slider">
        <figure style={{ backgroundColor: bg1color }}>
          <img src={img1} alt="Before" />
        </figure>
        <figure style={{ backgroundColor: bg2color, clipPath: `inset(0 ${100 - position}% 0 0)` }}>
          <img src={img2} alt="After" />
        </figure>
      </div>
    </div>
  );
};

export default ComparisonSlider;
