import React from 'react';

const LoadingIndicator = ({text = "로딩중 입니다."}) => {
  return (
    <div className="fixed inset-0 bg-[#CDCBCB] bg-opacity-70 flex flex-col justify-center items-center z-[10000000] pointer-events-auto">
      {/* 원형 SVG 스피너 */}
      <div className="h-48 w-48">
        <svg
          className="animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width="100%"
          height="100%"
        >
          <circle
            cx="50"
            cy="50"
            r="40"
            stroke="url(#gradient)"
            strokeWidth="10"
            fill="none"
            strokeLinecap="round"
          />
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="black" />
              <stop offset="100%" stopColor="transparent" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <p className="mt-8 text-center text-black font-bold text-[52px] max-sm:text-[24px]">
        {text}
      </p>
      <p className="text-center text-black font-bold text-[52px] max-sm:text-[24px]">
        잠시만 기다려주세요
      </p>
    </div>
  );
};

export const SmallLoadingIndicator = ({text = "로딩중 입니다."}) => {
  return (
    <div className="absolute lg:rounded-t-[20px] lg:top-0 top-[50px] bottom-0 left-0 right-0 bg-[#CDCBCB] bg-opacity-70 flex flex-col justify-center items-center z-[10000000] rounded-b-[20px] pointer-events-auto">
      {/* 원형 SVG 스피너 */}
      <div className="h-[33%] w-[33%]">
        <svg
          className="animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width="100%"
          height="100%"
        >
          <circle
            cx="50"
            cy="50"
            r="40"
            stroke="url(#gradient)"
            strokeWidth="10"
            fill="none"
            strokeLinecap="round"
          />
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="black" />
              <stop offset="100%" stopColor="transparent" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <p className="mt-8 text-center text-black font-bold text-[25px] max-sm:text-[24px]">
        {text}
      </p>
      <p className="text-center text-black font-bold text-[25px] max-sm:text-[24px]">
        잠시만 기다려주세요
      </p>
    </div>
  );
};

export default LoadingIndicator;