import React, { useEffect, useState } from 'react';
import { gradientText } from './modules/colors';
import studio1 from '../assets/images/studio/studio-sec1.png'
import studio2 from '../assets/images/studio/studio-sec2.png'
import studio3 from '../assets/images/studio/studio-sec3.png'

const TextImageBlock = ({ text, image, reverse }) => {
  return (
    <div className="md:flex text-white justify-center md:text-4xl text-3xl font-bold">
      {reverse ? (
        <>
          <div className="md:w-[533.33px] md:h-[300px] w-[auto] h-[auto] md:p-2 p-4 md:mt-0 mt-10 flex items-center justify-center">
            <img src={image} alt="placeholder" className="w-full h-full object-cover rounded-[25px]" />
          </div>
          <div className="md:w-[533.33px] md:h-[300px] w-[auto] md:p-2 p-4 md:mt-0 mt-10 flex items-center justify-center">
            <div>{text}</div>
          </div>
        </>
      ) : (
        <>
          <div className="md:w-[533.33px] md:h-[300px] w-[auto] md:mt-0 mt-10 flex items-center justify-center">
            <div>{text}</div>
          </div>
          <div className="md:w-[533.33px] md:h-[300px] w-[auto] h-[auto] md:p-2 p-6 md:mt-0 mt-10 flex items-center justify-center">
            <img src={image} alt="placeholder" className="w-full h-full object-cover rounded-[25px]" />
          </div>
        </>
      )}
    </div>
  );
};

const StudioLandingIntroduction = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const spanGradient = `${gradientText} font-extrabold`

  const blocks = [
    {
      text: (
        <div className='text-left'>
          <p>쉽게! 빠르게! 저렴하게!</p>
          <p><span className={spanGradient}>광고 이미지</span>를 제작해보세요!</p>
        </div>
      ),
      image: studio1,
    },
    {
      text: (
        <div className='text-right'>
          <p><span className={spanGradient}>내 상품 이미지</span>를 업로드하고</p>
          <p>클릭 한 번이면 끝!</p>
        </div>
      ),
      image: studio2,
      reverse: true,
    },
    {
      text: (
        <div className='text-right'>
          <p>비용이 높은 <span className={spanGradient}>스튜디오 배경</span>도</p>
          <p>촬영이 힘든 <span className={spanGradient}>자연 배경</span>도</p>
          <p>쉽게 만들어 드립니다!</p>
        </div>
      ),
      image: studio3,
    },
  ];

  return (
    <div className="pt-0 pb-10">
      {blocks.map((block, index) => (
        <TextImageBlock
          key={index}
          text={block.text}
          image={block.image}
          reverse={isMobile ? false : block.reverse || false}
        />
      ))}
    </div>
  );
};

export default StudioLandingIntroduction;