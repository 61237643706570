import React from "react";
import { FaInstagram } from 'react-icons/fa';
import { AiFillDiscord } from 'react-icons/ai';

function Texts( { children }) {
  return (
    <div className="block text-[13px] leading-[1.6] text-[#a7a7a7] break-keep font-[300] 
                    md:text-[18px] md:inline">
      {children}
    </div>
  )
}

function Span( { children , value=''} ) {
  return (
    <span className={`leading-[1.6] text-white font-semibold break-keep
                    ${value === 'title'
                    ? 'text-[15px] md:text-[24px]'
                    : 'text-[13px] md:text-[18px]'}
                    `}>
      {children}
    </span>
  )
}

function FooterContainer( { children } ) {
  return (
    <div className="w-auto h-[192px] mx-auto p-[10px] flex justify-between 
                    md:w-[70%] md:h-auto md:p-0 ">
      {children}
    </div>
  )
}

function LinkIcon( { children } ) {
  return (
    <div className="flex text-white ml-auto md:ml-0">
      {children}
    </div>
  )
}

function Br() {
  return <br className='hidden md:block' />;
}

function Footer() {
  const currentYear = new Date().getFullYear();

    return (
      <div className="pt-[20px] pb-[10px] box-border bg-black mt-auto
                      md:pt-[30px] md:pb-[20px]">
        <FooterContainer>
          <div className="block md:inline">
            <Span value='title'>© {currentYear} IMAGINERY.Inc</Span>
            <Br />
            <Texts>
              <Span>회사명</Span> 주식회사 이매지너리
            </Texts>
            <Br />
            <Texts>
              <Span> 주소</Span> 서울특별시 마포구 월드컵북로 98, 2층
            </Texts>
            <Texts>
              <Span> 대표</Span> 신준호
            </Texts>
            <Texts>
              <Span> 사업자등록번호</Span> 782-81-02642
            </Texts>
            <Br />
            <Texts>
              <Span> 통신판매업</Span> 제2023-서울마포-1651 호
            </Texts>
            <Texts>
              <Span> 대표번호</Span> 0507-1355-9741
            </Texts>
            <Texts>
              <Span> 이메일</Span> business@robobrush.ai
            </Texts>
          </div>
          <LinkIcon className="block md:inline">
            <a href="https://discord.gg/HmWfFD8Vmg" id="foot-discord" target="_blank" rel="noopener noreferrer"
                className="ml-[8px] text-[26px]
                          md:ml-[12px] md:text-[50px]">
              <AiFillDiscord />
            </a>
            <a href="https://www.instagram.com/imaginery_ai/" id="foot-insta" target="_blank" rel="noopener noreferrer"
                className="ml-[8px] text-[26px]
                          md:ml-[12px] md:text-[50px]">
              <FaInstagram />
            </a>
          </LinkIcon>
        </FooterContainer>
      </div>
    )
  }

export default Footer;