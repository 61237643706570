import React from 'react';
import ReactDOM from 'react-dom';
import { X } from 'lucide-react';

import { GradientButton } from '../Buttons'

const ModalMsg = (value) => {
  const noCredits =       `크레딧이 부족합니다.\n해당 기능은 ${value}개의 크레딧을 소모합니다.\n충전 페이지로 이동합니다.`;
  const invalidRequest =  "요청이 잘못되었습니다.";
  const loadingFailed =   "데이터를 불러오는 중 에러가 발생했습니다.\n잠시 후 다시 시도해주세요.";
  const generateFailed =  "이미지를 생성하는 도중 에러가 발생했습니다.\n문제가 반복되면 관리자에게 문의해주세요.";
  const imgLoadFailed =   "이미지를 로드하는 도중 에러가 발생했습니다.\n문제가 반복되면 관리자에게 문의해주세요.";
  const fillTheInput =    "검색어 혹은 프롬프트를 입력해주세요.";
  const noSymbols =       "공백 및 특수문자는 사용하실 수 없습니다.";
  const onlyDesktop =     "이 기능은 데스크탑에서 최적화 되어있습니다.\n데스크탑에서 이용해주세요.";
  const copySuccess =     "이미지를 클립보드에 복사했습니다.";
  const copyFailed =      "이미지 복사에 실패했습니다.";
  const wrongAccess =     "잘못된 경로로 접근했습니다.";
  const needImgSelect =   "이미지를 클릭해서 선택해주세요.";
  const noStage =         "캔버스가 초기화되지 않았습니다.\n다시 시도해주세요.";
  const bgRemoveError =   "배경 제거 중 오류가 발생했습니다.\n다시 시도해주세요.";
  const imgAnalError =    "이미지 분석 중 오류가 발생했습니다.\n다시 시도해주세요.";
  const NSFW =            "이미지 생성 도중 오류가 발생했거나\n부적절한 이미지를 감지했습니다.\n문제가 계속될 경우 관리자에게 문의하세요.";
  const downloadError =   "이미지를 다운로드 하는 도중\n오류가 발생했습니다.";
  const needFillInput =   "입력되지 않은 정보가 있습니다.\n정보를 올바르게 채워주세요.";
  const selectLimit =     `이미지는 ${value}개까지 선택할 수 있습니다.`;
  const needSelect =      `이미지를 ${value}개 이상 선택해주세요.`;
  const needLogin =       "이 서비스는 로그인 후에 이용하실 수 있습니다.";
  const iosInApp =        "로그인/회원가입을 위해\n크롬이나 사파리를 이용해주세요.\n현재 브라우저에서는 구글 로그인 기능이\n동작하지 않을 수 있습니다.";
  const notOpen =         "준비 중인 서비스입니다.";
  const paymentInfoMissing = "결제 정보가 누락됐습니다.\n다시 시도해주세요.";
  const paymentSuccess =  `결제 완료.\n${value} 크레딧이 지급됐습니다.`;
  const paymentValidFail = `결제 검증 실패\n${value}`;
  const paymentValidReqError = `결제 검증 요청 오류\n${value}`;
  const paymentFail =     `결제 실패: ${value}`;
  const safariCopyFailed = "Safari에서는 복사할 수 없습니다.\nChrome에서 시도해주세요."

  return {
    noCredits,
    invalidRequest,
    loadingFailed,
    generateFailed,
    imgLoadFailed,
    fillTheInput,
    noSymbols,
    onlyDesktop,
    copySuccess,
    copyFailed,
    wrongAccess,
    needImgSelect,
    noStage,
    bgRemoveError,
    imgAnalError,
    NSFW,
    downloadError,
    needFillInput,
    selectLimit,
    needSelect,
    needLogin,
    iosInApp,
    notOpen,
    paymentInfoMissing,
    paymentSuccess,
    paymentValidFail,
    paymentValidReqError,
    paymentFail,
    safariCopyFailed,
  };

};

const AlertModal = ({ isOpen, setIsOpen, message, buttonText='닫기', handleClick, handleClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target.id === 'overlay') {
      setIsOpen(false);
    }
  };

  const handleButtonClick = () => {
    if(handleClick) {
      handleClick();
    }
    else {
      setIsOpen(false);
    }
  };

  const handleCloseClick = () => {
    if(handleClose) {
      handleClose();
    } else {
      setIsOpen(false);
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      id="overlay"
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]"
      onClick={handleOverlayClick}
    >
      <div className="bg-white pt-10 pb-6 px-8 m-4 rounded-lg shadow-lg text-center max-w-xl w-full font-bold relative">
        <button
          onClick={handleCloseClick}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <X size={24}/>
        </button>
        <div className="h-8 sm:h-20 mx-[auto] flex justify-center" />
        {message.split('\n').map((m, idx) => (
          <h2 key={idx} 
              className={`overflow-hidden whitespace-nowrap 
                        text-[4.8vw] sm:text-[28px] 
                        mt-${2*Number(Boolean(idx))} sm:mt-${8*Number(Boolean(idx))}`}>
            {m}
          </h2>
        ))}
        {/* <h2 className="text-[5.7vw] sm:text-3xl">{message}</h2> */}
        <div className="h-8 sm:h-20 mx-[auto] flex justify-center" />
        <div className="mt-8 mx-[auto] flex justify-center">
          <GradientButton 
            text={buttonText}
            radius="10px"
            fontsize="24px"
            mobileFontSize="21px"
            size="w-[100%] h-[52px] sm:w-[100%] sm:h-[58px]"
            handleClick={() => {handleButtonClick()}}>
          </GradientButton>
        </div>
      </div>
    </div>,
    document.body // Portal을 통해 body에 렌더링
  );
};

export { ModalMsg, AlertModal };
