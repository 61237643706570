import React from 'react';
import { GradientButton } from '../Buttons';

const SignupModal = ({ isOpen, setIsOpen, message, message1, message2, onConfirm }) => {
  const Confirm = () => {
    onConfirm();
    setIsOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.id === 'overlay') {
      setIsOpen(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div
      id="overlay"
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]"
      onClick={handleOverlayClick}
    >
      <div className="bg-white p-8 m-4 rounded-lg shadow-lg text-center max-w-xl w-full font-bold">
        <h2 className="text-[5.7vw] sm:text-3xl">{message}</h2>
        {message1 && <h2 className="text-[5.7vw] sm:text-3xl mt-1">{message1}</h2>}
        {message2 && <h2 className="text-[5.8vw] sm:text-4xl mt-6 sm:mt-16">{message2}</h2>}
        <div className="mt-12 sm:mt-24 ml-[auto] mr-[auto] flex justify-center">
          <GradientButton
            btnId="signup-modal-yes"
            text="가입하기"
            mobileFontSize='16px'
            size='w-[220px] h-[42px] sm:w-[270px] sm:h-[50px]'
            handleClick={Confirm}
          />
        </div>
      </div>
    </div>
  );
};

export default SignupModal;