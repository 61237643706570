import React, { useRef, useEffect, useState } from 'react';
import { AlertModal, ModalMsg } from '../components/modals/AlertModal';
import ForbiddenWords from '../ForbiddenWords'
import { WandSparkles } from 'lucide-react';

// 공통 로직
const handleInputChange = (e, setPrompt) => {
  const inputValue = e.target.value;
  const filteredInput = ForbiddenWords.reduce((acc, word) => {
    return acc.split(word).join("");
  }, inputValue);

  setPrompt(filteredInput);
};

const isValidSearchQuery = (query) => {
  if (!query.trim()) {
    // alert(ModalMsg().fillTheInput);
    return ModalMsg().fillTheInput;
  }
  if (/^[^a-zA-Z0-9가-힣]+$/.test(query)) {
    // alert(ModalMsg().noSymbols);
    return ModalMsg().noSymbols;
  }
  return true;
};

// 외부로 분리된 onSubmit 함수
const handleFormSubmit = (e, prompt, setPrompt, setIsOpen, setModalMsg, handleSubmit) => {
  e.preventDefault();
  const r = isValidSearchQuery(prompt);
  if (r !== true) {
    setModalMsg(r);
    setIsOpen(true);
    setPrompt('');
    return;
  }
  handleSubmit(prompt);
};

// Prompt 생성용 컴포넌트
export const InputWand = ({ handleSubmit, prompt, setPrompt, placeholder = '생성할 이미지에 대한 설명을 입력해주세요.', buttonText = '생성하기', onFocus, onBlur, inputRef, disabled, btnDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  return (
    <>
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>
      <form className='flex h-16 w-full border-b-2 border-b-white justify-center gap-1' 
            onSubmit={(e) => handleFormSubmit(e, prompt, setPrompt, setIsOpen, setModalMsg, handleSubmit)}>
        <input
          className='h-[98%] w-[98%] bg-transparent text-white text-2xl my-0 max-sm:text-sm'
          type="text"
          name="prompt"
          value={prompt}
          onChange={(e) => handleInputChange(e, setPrompt)}
          placeholder={placeholder}
          autoComplete='off'
          onFocus={onFocus}
          onBlur={onBlur}
          ref={inputRef}
          disabled={disabled}
        />
        <button className='flex justify-center items-center aspect-square md:w-[50px] md:h-[50px] w-[40px] h-[40px] my-auto bg-gradient-to-r from-blue-500 to-purple-600 rounded-full' type="submit" id="wand-prompt-gen" disabled={btnDisabled}>
          {buttonText}
        </button>
      </form>
    </>
  );
};

// 검색창형
export const InputSearch = ({
  handleSubmit, prompt, setPrompt, placeholder = '생성할 이미지를 입력해주세요..', buttonText = '제출하기', btnClass = '', btnId = '', onFocus, onBlur, inputRef, disabled, btnDisabled
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  return (
    <>
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>
      <div className='bg-white w-full max-w-[350px] h-[42px] mx-auto px-[10px] py-[5px] box-border rounded-full
                      sm:max-w-[800px] sm:h-[56px]'>
        <div className="w-full h-full flex justify-center items-center mx-auto box-border">
          <form className='w-full flex justify-between flex-wrap' 
                onSubmit={(e) => handleFormSubmit(e, prompt, setPrompt, setIsOpen, setModalMsg, handleSubmit)}>
            <input
              type="text"
              name="prompt"
              value={prompt}
              onChange={(e) => handleInputChange(e, setPrompt)}
              placeholder={placeholder}
              autoComplete='off'
              onFocus={onFocus}
              onBlur={onBlur}
              ref={inputRef}
              disabled={disabled}
              className='w-auto flex-grow bg-transparent border-none text-black transition-all duration-300 text-[12px] ml-[10px] focus:outline-none
                        sm:text-[16px] sm:ml-[15px]'
            />
            <button
              className={`border-none text-[rgba(15,37,85,.8)] text-[14px] font-bold box-border mr-[10px] bg-transparent cursor-pointer hover:text-[#062b8a] hover:scale-105 transition-all duration-200
                          sm:w-[80px] sm:text-[16px] ${btnClass}`}
              type="submit"
              id={btnId}
              disabled={btnDisabled}
            >
              {buttonText}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

// 버튼 형태 인풋
export const InputBtn = ({ isKeyboardVisible, inputValue, setInputValue, handleGenerateClick, isGenerating, progress, placeholder = '생성할 이미지를 입력하세요.', btnId }) => {
  const [parentWidth, setParentWidth] = useState(0);
  const [buttonWidth, setButtonWidth] = useState(48);
  const parentRef = useRef(null);

  // 부모 요소의 너비를 감지하는 useEffect
  useEffect(() => {
    if (parentRef.current) {
      const handleResize = () => {
        setParentWidth(parentRef.current.offsetWidth);
        setButtonWidth(window.innerWidth <= 768 ? 36 : 48);
      };
      handleResize();
      window.addEventListener('resize', handleResize); 
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [parentRef]);

  // translateX 계산 (progress에 따른 위치 계산)
  const translateXValue = isGenerating
  ? `translateX(${-(progress / 100) * (parentWidth - buttonWidth)}px)`
  : 'translateX(0)';

  return (
    <div className="relative w-full" ref={parentRef}>
      <div
        className={`
          relative inline-flex justify-between items-center sm:w-full w-[100%] sm:h-[50px] h-[36px] text-white font-medium text-[16px]
          rounded-[25px] bg-transparent p-[2px] transition-all duration-200 ease-in-out
          bg-gradient-to-r from-blue-500 to-purple-600
          ${isGenerating ? 'animate-waves' : ''}
        `}
        style={{
          backgroundImage: isGenerating
            ? 'linear-gradient(270deg, #3b82f6, #933bff, #3b82f6)'
            : '',
          backgroundSize: isGenerating ? '200% 200%' : '100% 100%',
          backgroundPosition: isGenerating ? `${progress}% 50%` : 'center',
        }}
      >
        <div
          className={`flex justify-center items-center w-full h-full bg-[#24303F] rounded-[23px]
            ${isGenerating ? 'bg-transparent' : ''}
          `}
        >
          {!isGenerating && (
            <input
              type="text"
              value={inputValue}
              onChange={(e) => handleInputChange(e, setInputValue)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleGenerateClick();
                }
              }}
              disabled={isGenerating}
              placeholder={placeholder}
              className="w-[85%] h-[80%] sm:text-[16px] text-[10px] text-white placeholder-[#717171] bg-[#24303F] sm:border-b border-white outline-none sm:pl-4 pl-1"
            />
          )}
          <button
            id={btnId}
            onClick={handleGenerateClick}
            disabled={isGenerating}
            className={`
              absolute right-0 sm:w-[48px] sm:h-[48px] w-[36px] h-[36px] rounded-full flex items-center justify-center
              bg-gradient-to-r from-blue-500 to-purple-600
              ${isGenerating ? 'cursor-not-allowed' : ''}
            `}
            style={{
              transform: translateXValue,
              transition: 'transform 0.1s ease-in-out',
            }}
          >
            <WandSparkles size={24} color="#FFFFFF" />
          </button>
        </div>
      </div>
    </div>
  );
};

// SideController 텍스트 에어리어
export const InputTextArea = ({ isKeyboardVisible, prompt, setPrompt, handleEditClick, placeholder="텍스트를 입력하여 선택한 영역에 수정을 해보세요." }) => {
  return (
    <div className="flex justify-center items-center w-full h-full bg-[#24303F] rounded-[15px]">
      <textarea
        value={prompt}
        onChange={(e) => handleInputChange(e, setPrompt)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleEditClick();
            e.key = '';
          }
        }}
        placeholder={placeholder}
        className={`w-full sm:h-[150px] h-full sm:text-[16px] text-[12px] text-white placeholder-[#717171] bg-[#111820] outline-none px-[15px] pt-[9px] box-border resize-none
                  ${isKeyboardVisible ? 'bg-[#24303F] rounded-[50px]' : 'bg-[#111820] rounded-[15px]' }`}
      />
    </div>
  )
}

export const MobileInputBtn = ({ activeTab, tabs, inputValue, setInputValue, handleGenerateClick, isGenerating, progress, placeholder = '생성할 이미지를 입력하세요.', }) => {
  const activeTabData = tabs.find((tab) => tab.name === activeTab);
  
  if (!activeTabData) { return null; }

  return (
    <div className="w-full fixed bottom-0 flex bg-[#24303F]">
      <div className="sm:w-[auto] w-[17%] sm:p-[15px] flex flex-col sm:gap-[5px] items-center bg-[#24303F] shadow-md
                          p-[1px] gap-[1px]">
        <button className="p-2 flex flex-col items-center" key={activeTabData.name}>
          <div className='rounded-[25px] sm:py-[10px] sm:px-[15px] py-[5px] px-[10px] bg-[#933BFF]'>
            <activeTabData.Icon className='text-white sm:size-[28px] size-[16px]' />
          </div>
          <span className='mt-1 sm:text-[12px] text-[9px] text-white'>
            {activeTabData.label}
          </span>
        </button>
      </div>
      <div className="w-full flex justify-center items-center px-[15px]">
        <InputBtn
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleGenerateClick={handleGenerateClick}
          isGenerating={isGenerating}
          progress={progress}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};