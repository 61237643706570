import React, { useRef, useState } from 'react';
import ComparisonSlider from '../components/ComparisonSlider';
import perfume0 from '../assets/images/concept/perfume0.png';
import perfume1 from '../assets/images/concept/perfume1.png';
import '../styles/StudioLanding.css'
import { gradientText } from './modules/colors';
import { GradientOutlineButton } from './Buttons';

const StudioLandingInner = ({ handleButtonClick }) => {
  const containerRef = useRef(null);

  // 슬라이더 배열
  const imagePairs = [
    { img1: perfume1, img2: perfume0 },
    { img1: perfume0, img2: perfume1 },
  ];
  const [currentPairIndex, setCurrentPairIndex] = useState(0);

  const toggleImages = () => {
    setCurrentPairIndex((prevIndex) => (prevIndex + 1) % imagePairs.length);
    // console.log('toggle', currentPairIndex, imagePairs[currentPairIndex])
  };

  return (
    <div className='StudioLandingInner'>
      <div className='container'>
        <div className='upload-area'>
          <div className='canvas-area' ref={containerRef}>
            <h1>30초 만에 만드는</h1>
            <h2 className={gradientText}>완벽한 제품사진</h2>
            <div className='mt-20 desktop'>
              <GradientOutlineButton
                btnId='studio-land-start-bottom'
                text='무료로 시작하기'
                mobileFontSize='16px'
                size='w-[200px] h-[42px] sm:w-[200px] sm:h-[50px]'
                handleClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
        <div className='intro-area'>
          <div className='mershikkeng'>
            <ComparisonSlider
              bg1color='#44505b'
              bg2color='#44505b'
              img1={imagePairs[currentPairIndex].img1}
              img2={imagePairs[currentPairIndex].img2}
              autoSlide
              onSlideEnd={toggleImages}
            />
          </div>
          <div className='mt-12 mobile'>
            <GradientOutlineButton
              btnId='studio-land-start-bottom'
              text='무료로 시작하기'
              mobileFontSize='16px'
              size='w-[200px] h-[42px] sm:w-[200px] sm:h-[50px]'
              handleClick={handleButtonClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudioLandingInner;
