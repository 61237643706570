import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';
import LoadingIndicator from './LoadingIndicator';
import { gradientText } from './modules/colors';
import { AlertModal, ModalMsg } from '../components/modals/AlertModal';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, logout, googleLogin, authloading } = useAuth();
  const { totalCredits, creditLoading } = useCredit();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [isToggleVisible, setIsToggleVisible] = useState(false);
  const toggleRef = useRef(null);
  const [isOn, setIsOn] = useState(false); // toggler 상태

  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  
  // 사용자 로그인 상태 변경 감지
  useEffect(() => {
    if (currentUser) {
      setUserData({
        nickname: currentUser.nickname || currentUser.name,
        picture: currentUser.picture,
      });
    } else {
      setUserData({});
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, [currentUser, authloading]);

  // 배경 클릭 시 토글 메뉴 닫기
  useEffect(() => {
    function handleClickOutside(event) {
      if (toggleRef.current && !toggleRef.current.contains(event.target)) {
        setIsToggleVisible(false);
        setIsOn(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleRef]);

  // 특정 경로에서는 Navbar를 렌더링하지 않음
  if (location.pathname === '/iphonewarning') { return null; }

  // 유저명 클릭 시 토글 메뉴 토글
  const handleMenuToggle = () => {
    setIsToggleVisible((prev) => !prev);
    setIsOn((prev) => !prev);
  };

  // 로그아웃 처리
  const handleLogout = async () => {
    await logout();
    setIsToggleVisible(false);
    navigate('/');
  };

  // 스크롤 이동 함수
  const handleScrollTo = (desktopY, mobileY) => {
    const screenWidth = window.innerWidth;
    const targetY = screenWidth < 768 ? mobileY : desktopY;

    window.scrollTo({
      top: targetY,
      behavior: 'smooth',
    });
  };

  const handleNavMenuClick = (path) => {
    navigate(path);
    setIsToggleVisible(false);
    setTimeout(() => {
      handleScrollTo(0, 0);
    }, 50);
  }

  // 네비게이션 메뉴 아이템
  const MemberMenu = ({ path, label, BtnId }) => (
    <div className="border-b border-[#2a3945] h-[50px] m-0 flex items-center justify-center bg-[#111820]
                    hover:text-[#87ceeb] transition duration-200 cursor-pointer"
      id={BtnId}
      onClick={() => handleNavMenuClick(path)} >
      {label}
    </div>
  );

  // 로그인 상태일시 좌우 패딩 조절
  const hasPaddingZero = !!currentUser

  return (
    <div className={`fixed top-0 z-[1400] w-screen sm:h-[56px] h-[40px] bg-[#111820] flex justify-center items-center box-border select-none px-0 py-[1.5em] sm:py-[5px] px-0
                ${hasPaddingZero ? 'sm:px-[2em]' : 'sm:px-[15%]'}`}>
      {authloading && <LoadingIndicator />}

      <div className="flex flex-1 justify-between w-full max-w-[1920px]">
        <div className="sm:w-[250px] w-[130px] flex justify-start items-center">
         <div className="cursor-pointer ml-[15px] sm:ml-[0px]" >
            <p className={`${gradientText} font-extrabold inline-block sm:text-[36px] text-[28px]`}
              onClick={() => handleNavMenuClick('/')}
            >ROBOBRUSH</p>
          </div>
        </div>

        <div className="hidden md:flex w-[35%] justify-around items-center text-white md:text-[16px] lg:text-[20px] font-bold transition-transform duration-200">
          <button className="hover:text-[#ffd700] hover:scale-105 cursor-pointer"
            id="nav-tool"
            onClick={() => handleNavMenuClick('/mycanvas-home')} >
            AI 캔버스
          </button>
          <button className="hover:text-[#ffd700] hover:scale-105 cursor-pointer"
            id="nav-studio"
            onClick={() => handleNavMenuClick('/conceptlanding')} >
            AI 스튜디오
          </button>
          <button className="hover:text-[#ffd700] hover:scale-105 cursor-pointer"
            id="nav-pricing"
            onClick={() => handleNavMenuClick('/pricing')} >
            가격정책
           </button>
        </div>

        <div className="w-[130px] sm:w-[250px] flex justify-end items-center ">
          {isLoading ? (
            <div></div>
          ) : (
            <div className="flex items-center">
              {currentUser ? (
                <>
                  <div className={`${isOn ? 'on' : ''}
                                  relative h-auto w-[140px] bg-transparent justify-end items-center text-center mx-auto
                                  sm:w-[250px]`} 
                    ref={toggleRef} >
                    <div className="text-[0px] w-[120px] m-0 flex justify-end items-center text-white text-[18px] h-[40px] 
                                    sm:w-[auto] sm:text-[16px] mx-auto"
                                    id="profile-toggle"
                                    onClick={handleMenuToggle}>
                      <div className="hidden sm:block text-[18px] hover:scale-[1.02] cursor-pointer">
                        {userData.nickname}
                      </div>
                      {userData.picture && (
                        <img src={userData.picture} alt="x"
                          className="w-[36px] m-0 sm:w-[50px] rounded-[50%] sm:ml-[14px] cursor-pointer hover:scale-[1.03]" />
                      )}
                    </div>

                    <div className={`absolute top-[44px] sm:top-[54px] right-0 w-[150px] bg-[#111820] border border-[#2a3945] rounded-lg shadow-lg z-[1401] text-white font-bold
                                    text-[14px] font-bold rounded-[10px] outline outline-1 outline-[#2a3945] shadow-[0px_8px_12px_4px_rgba(34,49,63,0.4)]
                                    sm:text-[18px] sm:w-[250px]
                                    transition-all duration-300 ${isToggleVisible ? 'block opacity-100' : 'hidden opacity-0'}`}>

                      {!creditLoading && (
                        <div className="h-[50px] bg-[#111820] flex items-center justify-center border-b border-[#2a3945]">
                          크레딧: {totalCredits}
                        </div>
                      )}
                      <MemberMenu path="/mypage" label="내 정보" BtnId="profile-mypage" />
                      <MemberMenu path="/mycanvas-home" label="AI 캔버스" BtnId="profile-aicanvas" />
                      <MemberMenu path="/conceptlanding" label="AI 스튜디오" BtnId="profile-aistudio" />
                      <MemberMenu path="/pricing" label="가격정책" BtnId="profile-pricing" />
                      {currentUser && currentUser.isAdmin && (
                        <MemberMenu path="/admin/prompt" label="DB Reader" />
                      )}
                      <div className="border-b border-[#2a3945] h-[50px] text-[#ffff00] m-0 flex items-center justify-center bg-[#111820]
                                      hover:text-[#ff0000] transition duration-200 cursor-pointer"
                        id="profile-logout"
                        onClick={handleLogout}>
                        로그아웃
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-[0px] w-[120px] m-0 flex justify-end items-center text-white text-[18px] h-[50px] 
                                sm:w-[auto] sm:h-[40px] sm:text-[16px] mx-auto">
                  <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>
                  <button className="w-[100px] h-[36px] text-[14px] font-semibold bg-gradient-to-r from-blue-500 to-purple-600 mr-[10px]
                                    sm:w-[150px] sm:h-[50px] sm:text-[18px] sm:mr-0 rounded-[50px]
                                    hover:text-[#ffd700] hover:sm:text-[18.5px] transition-all duration-200 cursor-pointer" 
                    id="nav-start" 
                    onClick={() => {
                      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                      const isInAppBrowser = /Instagram|FBAN|FBAV|FB_IAB|FBIOS|FB4A|FBSV|Twitter|Line|KAKAOTALK|NAVER|Naver|Firefox|FxiOS/i.test(userAgent);
                      if (isInAppBrowser && /iPhone|iPad|iPod/i.test(userAgent)) {
                        setIsOpen(true);
                        setModalMsg(ModalMsg().iosInApp);
                      }
                      else {
                        googleLogin();
                      }
                    }}>
                    시작하기
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;