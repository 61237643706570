import React from 'react';
import ReactDOM from 'react-dom';
import { X } from 'lucide-react';

const MobileUserInfo = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed bottom-36 inset-0 text-white flex items-center justify-center z-100">
      <div className="bg-[#111820] px-8 py-12 rounded-lg shadow-xl max-w-[450px] w-3/4 lg:min-h-[240px] mx-4 lg:flex relative">
        <button
          onClick={onClose}
          className="absolute right-8 top-14 hover:text-red-600 text-white font-bold rounded"
        >
          <X size={16}/>
        </button>
        {children}
      </div>
    </div>,
    document.body // Portal을 통해 body에 렌더링
  );
};

export default MobileUserInfo;
