import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';

import MobileUserInfo from '../components/modals/MobileUserInfo';
import config from '../config';

import { Settings } from 'lucide-react';
import { AlertModal, ModalMsg } from '../components/modals/AlertModal';
import { handleAxios } from '../components/modules/AxiosModule';
import LoadingIndicator from '../components/LoadingIndicator';

const MyPage = () => {
  const navigate = useNavigate();
  const { currentUser, deleteAccount } = useAuth();
  const { freeCredits, paidCredits } = useCredit();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAscending, setIsAscending] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  
  const itemsPerPage = 9;

  useEffect(() => {
    sessionStorage.removeItem('imgUuid');
  }, []);

  useEffect(() => {
    const fetchUserHistory = async () => {
      setIsLoading(true);
      
      try {
        const storedToken = JSON.parse(localStorage.getItem('token'));
        if (storedToken && storedToken.token) {
          const apiURL = config.USER_GET_IMAGES;
          const response = await handleAxios("get", apiURL);

          if (Array.isArray(response.data)) {
            setImages(response.data);
          }
        }
      } catch (error) {
        setModalMsg(ModalMsg().loadingFailed);
        setIsOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (currentUser) {
      fetchUserHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (images.length === 0) {
      setIsLoading(false);
    }
  }, [images]);

  useEffect(() => {
    if (imagesLoaded === images.length && images.length > 0) {
      setIsLoading(false);
    }
  }, [imagesLoaded, images]);

  if (!currentUser) {
    return <div style={{ margin: '150px auto', textAlign: 'center', fontSize: '40px', color: 'red' }}>로그인이 필요합니다.</div>;
  }

  const toggleSortOrder = () => {
    setIsLoading(true);
    setIsAscending(!isAscending);
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const handleImageLoad = () => {
    setImagesLoaded(prevCount => prevCount + 1);
  };

  const handlePageChange = (pageNumber) => {
    setIsLoading(true);
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'auto' });
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const sortedImages = isAscending ? images : images.slice().reverse();
  const indexOfLastImage = currentPage * itemsPerPage;
  const indexOfFirstImage = indexOfLastImage - itemsPerPage;
  const currentImages = sortedImages.slice(indexOfFirstImage, indexOfLastImage);

  const totalPages = Math.ceil(images.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageRange = 1; // 현재 페이지 앞뒤로 보여줄 페이지 수

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // const startPages = [1, 2];
      // const endPages = [totalPages - 1, totalPages];
      const startPages = [1];
      const endPages = [totalPages];

      // Add start pages
      for (let i = 0; i < startPages.length; i++) {
        pageNumbers.push(startPages[i]);
      }

      // Add middle pages with "..." separators
      if (currentPage <= 3) {
        for (let i = 2; i <= 4; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
      } else {
        if (currentPage > pageRange + 2) {
          pageNumbers.push("...");
        }
        for (let i = Math.max(currentPage - pageRange, 3); i <= Math.min(currentPage + pageRange, totalPages - 1); i++) {
          pageNumbers.push(i);
        }
        if (currentPage < totalPages - pageRange - 1) {
          pageNumbers.push("...");
        }
      }

      // Add end pages
      for (let i = 0; i < endPages.length; i++) {
        pageNumbers.push(endPages[i]);
      }
    }

    return pageNumbers;
  };

  // modal
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openUpdateInfo = () => {
    navigate('/updateinfo');
  }

  // 회원 탈퇴
  const handleDeleteAccount = () => {
    if (window.confirm('정말 탈퇴하시겠습니까?\n탈퇴된 계정은 복구할 수 없습니다.')) {
      deleteAccount();
    }
  };

  return (
    <>
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>

      <div className='flex h-fit mt-40 px-[15%] items-center text-center
                    max-lg:block max-lg:w-[80%] max-lg:mx-auto max-lg:my-20 max-lg:px-0'>
        <div className='w-[34%] mr-[10%]
                      max-lg:w-[100%] max-mr-0'>
          <div className='block text-white
                        max-lg:gap-[25px]'>
            {currentUser.picture && <img className='w-[60%] h-[60%] rounded-full mx-auto
                                            max-lg:w-[250px] max-lg:h-[250px]'
                                            src={currentUser.picture} alt="프로필 사진" />}
            <div className='flex flex-col w-full justify-center items-center gap-[4px] mt-4
                          '>
              <p className='break-word font-bold text-3xl mb-0
                          '>{"@"+currentUser.nickname || '등록된 닉네임이 없습니다.'}</p>
              <div className='flex items-center'>
                <p className='break-word text-center text-base leading-[1] mt-1
                            max-lg:text-[18px]'>{currentUser.email || '등록된 이메일이 없습니다.'}</p>
                {/* modal */}
                <div className="lg:hidden">
                  <button
                    onClick={openModal}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold ml-2 py-1 px-1 rounded"
                  >
                    <Settings />
                  </button>
                  <MobileUserInfo isOpen={isModalOpen} onClose={closeModal}>
                    <div className='md:text-base text-sm'>
                      <div className='flex w-full mx-auto pb-2 gap-2 items-center
                                    border-b border-gray-500 
                                    text-left text-xl text-gray-300'>
                        <Settings />
                        <span>계정</span>
                      </div>
                      <div className='flex w-[90%] justify-between font-bold mx-auto pt-3'>
                      <span>무료 크레딧</span>
                        <div className='w-[40%] text-center font-bold'><span>{freeCredits}</span></div>
                      </div>
                      <div className='flex w-[90%] justify-between font-bold mx-auto pt-3'>
                      <span>유료 크레딧</span>
                        <div className='w-[40%] text-center font-bold'><span>{paidCredits}</span></div>
                      </div>
                      <div className='flex w-[90%] justify-between font-bold mx-auto pt-3'>
                      <span>휴대폰번호</span>
                        <div className='w-[40%] text-center font-bold'><span>{currentUser.phone || '정보없음'}</span></div>
                      </div>
                      <div className='flex w-[90%] justify-between font-bold mx-auto pt-3'>
                      <span>직업</span>
                        <div className='w-[40%] text-center font-bold'><span>{currentUser.occupation || '정보없음'}</span></div>
                      </div>
                      <div className='flex flex-col justify-center w-full mt-6 gap-2
                                    border-b border-gray-500 pb-2'>
                        <button className='text-yellow-500 font-bold '
                                id='mypage-edit'
                                onClick={openUpdateInfo}>
                                  정보 수정
                        </button>
                        <button className='text-red-600 font-bold '
                                id='mypage-deleteAccount'
                                onClick={handleDeleteAccount}>
                                  계정 삭제
                        </button>
                      </div>
                    </div>
                  </MobileUserInfo>
                </div>
                {/*  */}
              </div>
              {/* non modal */}
              <div className='hidden w-full p-6
                            lg:block'>
                <div className='flex border-b border-gray-50 text-left mt-3 mx-auto pb-2'>
                  <Settings />
                  <span>계정</span>
                </div>
                <div className='flex w-[90%] justify-between mx-auto pt-2'>
                  <span>무료 크레딧</span>
                  <div className='w-[40%] text-center'><span>{freeCredits}</span></div>
                </div>
                <div className='flex w-[90%] justify-between mx-auto pt-2'>
                  <span>유료 크레딧</span>
                  <div className='w-[40%] text-center'><span>{paidCredits}</span></div>
                </div>
                <div className='flex w-[90%] justify-between mx-auto pt-2'>
                  <span>휴대폰번호</span>
                  <div className='w-[40%] text-center'><span>{currentUser.phone || '정보없음'}</span></div>
                </div>
                <div className='flex w-[90%] justify-between mx-auto pt-2'>
                  <span>직업</span>
                  <div className='w-[40%] text-center'><span>{currentUser.occupation || '정보없음'}</span></div>
                </div>
                <div className='flex flex-col justify-center mt-4 pr-[5%] gap-2 border-b border-gray-500 pb-2'>
                  <button className='text-yellow-500 font-bold text-base'
                          id='mypage-edit'
                          onClick={openUpdateInfo}>
                            정보 수정
                  </button>
                  <button className='text-red-600 font-bold text-base'
                          id='mypage-deleteAccount'
                          onClick={handleDeleteAccount}>
                            계정 삭제
                  </button>
                </div>
              </div>
              {/*  */}
              
            </div>
          </div>
        </div>
        <div className='w-[56%] mt-0
                      max-lg:w-full'>
          {isLoading && (
            <LoadingIndicator/>
          )}
          <div className='text-center
                        max-lg:mt-8'>
            <button onClick={toggleSortOrder}
              className='w-[200px] h-[40px] rounded-2xl text-base bg-white'>
              {isAscending ? '내림차순으로 보기' : '오름차순으로 보기'}
            </button>
            <div className='grid grid-cols-3 gap-1 mt-5'>
              {currentImages.map((image, index) => (
                <img key={index} src={image} alt={`${index}`} onLoad={handleImageLoad} />
              ))}
            </div>
            <div className='flex justify-center mt-5 mb-12'>
              {renderPageNumbers().map((number, index) => (
                <button
                  key={index}
                  onClick={() => typeof number === 'number' && handlePageChange(number)}
                  className={(number === currentPage ? 'bg-blue-500 text-white' : 'bg-transparant text-white')
                          + ' mx-1 my-1 px-4 py-2 rounded-md font-bold text-base cursor-pointer'
                          + ' max-lg:p-2'
                  }
                  disabled={number === "..."}
                >
                  {number}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPage;
