import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function AdminNavBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { label: '회원정보', path: '/admin/memberlist' },
    { label: '히스토리', path: '/admin/history' },
    { label: '생성하기', path: '/admin/prompt' },
    { label: '조합하기', path: '/admin/reference' },
    { label: '텍스트생성', path: '/admin/prompthistory' },
    { label: '랜딩샘플', path: '/admin/sample' },
  ];

  const isActive = (path) => location.pathname === path;

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="fixed top-[48px] md:top-[56px] z-[1300] w-full h-[74px] bg-[rgba(17,24,32,.8)] flex justify-between items-center px-4 box-border select-none">
      {/* 메뉴 토글 버튼 (모바일 전용) */}
      <button className="md:hidden text-white text-3xl cursor-pointer"
              onClick={toggleMobileMenu}
      >
        ☰
      </button>

      <div className="flex justify-start items-center w-full">
        {/* 데스크탑 및 모바일 메뉴 */}
        <div className={`flex flex-col md:flex-row md:items-center space-x-0 md:space-x-10 text-white w-full justify-center ${
                        isMobileMenuOpen
                          ? 'fixed top-16 left-0 w-full bg-[rgba(17,24,32,0.95)] z-40 p-5'
                          : 'hidden md:flex'
        }`} >
          {menuItems.map((item) => (
            <div key={item.path}
              className={`text-lg md:text-xl my-2 md:my-0 transition-transform duration-200 cursor-pointer ${
                isActive(item.path) ? 'font-semibold underline scale-105 text-shadow-lg' : ''
              }`}
              onClick={() => navigate(item.path)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminNavBar;