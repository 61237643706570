import React from 'react';
import { gradientText } from './modules/colors';

const LandingCenterBox = ({ imageSrc, text, highlightWords }) => {
  const spanGradient = `${gradientText} font-extrabold`

  // 하이라이트 처리된 텍스트 반환
  const renderHighlightedText = (line) => {
    if (!highlightWords || highlightWords.length === 0) return line;

    const parts = line.split(new RegExp(`(${highlightWords.join('|')})`, 'gi'));
    return parts.map((part, index) =>
      highlightWords.includes(part) ? (
        <span key={index} className={spanGradient}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="flex flex-col mt-40 p-6 items-center justify-center text-center space-y-6">
      {imageSrc && (
        <div className="w-[auto] h-[auto] md:w-[800px] md:h-[450px]">
          <img
            src={imageSrc}
            alt="Landing"
            className="w-full h-full object-cover rounded-[25px]"
          />
        </div>
      )}

      <div className="text-[22px] md:text-[50px] font-bold text-white">
        {text.map((line, index) => (
          <p key={index}>{renderHighlightedText(line)}</p>
        ))}
      </div>
    </div>
  );
};

export default LandingCenterBox;
