import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';

import { TagProvider } from '../context/TagContext';
import { ImgProvider } from '../context/ImageContext';
import { Wand, WandTags, WandTag, WandInputForm } from '../components/Wand';
import ImageHistory from '../components/ImageHistory';
import LoadingIndicator from '../components/LoadingIndicator';

const MyCanvasHome = () => {
  const { currentUser, loginCheck, authloading } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    sessionStorage.removeItem('imgUuid');
  }, []);

  useEffect(() => {
    loginCheck()
  // eslint-disable-next-line
  }, [currentUser, authloading]);

  return (
    <>
      {isLoading && <LoadingIndicator text="로보브러시가 이미지를 만들고 있어요." />}
      <div className='flex flex-col min-h-screen px-[15%] pt-16
                      max-lg:px-4'>
        <TagProvider>
          <Wand>
            <WandTags>
              <WandTag value="GEN">텍스트로 이미지 생성</WandTag>
              <WandTag value="COM">이미지와 이미지 조합</WandTag>
            </WandTags>
            <ImgProvider>
              <WandInputForm
                btnId='prompt-gen'
                setIsLoading={setIsLoading}
              />
            </ImgProvider>
          </Wand>
          <div className='w-full mt-6 mb-12 mx-auto'>
            <ImageHistory currentUser={currentUser} />
          </div>
        </TagProvider>
      </div>

    </>
  );
};

export default MyCanvasHome;
